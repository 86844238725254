import React, { useState } from 'react';
import { ContainerLinks } from "../ContainerLinks";
import LogoDexco from '../../assets/logo-dexco-hw.svg';
import LogoDeca from '../../assets/logo-deca-hw.svg';
import LogoPortinari from '../../assets/logo-portinari-hw.svg';
import LogoHydra from '../../assets/logo-hydra-hw.svg';
import LogoDuratex from '../../assets/logo-duratex-hw.svg';
import LogoCastelatto from '../../assets/logo-castelatto-hw.svg';
import LogoCeusa from '../../assets/logo-ceusa-hw.svg';
import LogoDurafloor from '../../assets/logo-durafloor-hw.svg';
import { ContainerFinalFooter, 
  ContainerFooter, 
  ContainerLogos, 	
  ContainerModal, 
  ContentModal 
} from "./style";
import { CloseOutlined } from '@ant-design/icons/lib/icons';
import { PrivacyPolicy } from '../PrivacyPolicy';

export function Footer() {

  const [isModalVisible, setIsModalVisible] = useState();

  function openModal() {
    document.body.style.overflow='hidden'
    setIsModalVisible(true);
  }

  function closeModal() {
    document.body.style.overflowY='scroll'
    setIsModalVisible(false);
  }

  return(
    <>
      <ContainerFooter>
        <div className="container-links">
          <ContainerLinks 
            name= 'PRODUTOS'
            links={[
              {name: 'Banheiro', href: 'https://www.deca.com.br/produtos/ambientes/banheiro/'},
              {name: 'Cozinha', href: 'https://www.deca.com.br/produtos/ambientes/cozinha/'},
              {name: 'Lavanderia', href: 'https://www.deca.com.br/produtos/ambientes/lavanderia/'}
            ]}
          />

          <ContainerLinks 
            name= 'DECA PARA PROFISSIONAIS'
            links={[
              {name: 'DEXperience', href: 'https://www.dexperience.com.br'},
              {name: 'Prêmio Deca', href: 'https://www.premiodeca2021.com.br'}
            ]}
          />

          <ContainerLinks 
            name= 'SOBRE NÓS'
            links={[
              {name: 'História', href: 'https://www.deca.com.br/historia/'},
              {name: 'Fábricas', href: 'https://www.deca.com.br/fabricas/'},
              {name: 'Política de Qualidade', href: 'https://www.deca.com.br/politica-da-qualidade/'},
              {name: 'Sustentabilidade', href: 'https://www.dex.co/esg/estrategia/'}
            ]}
          />

          <ContainerLinks 
            name= 'DECA AJUDA'
            links={[
              {name: 'Comercial', href: 'comercial.deca.com.br'},
              {name: 'Contatos', href: 'https://www.deca.com.br/ajuda/contatos/'},
              {name: 'Perguntas Frequentes', href: 'https://www.deca.com.br/ajuda/duvidas-frequentes/'},
              {name: 'Espaço Design Deca', href: 'https://www.deca.com.br/ajuda/espaco-de-design-deca/'},
              {name: 'Revendedores Exclusivos', href: 'https://www.deca.com.br/ajuda/lojas-revendedores/'},
              {name: 'Serviços Autorizados', href: 'https://www.deca.com.br/ajuda/servico-autorizado/'},
            ]}
          />
        </div>
        <ContainerFinalFooter>
        <a href="https://www.dex.co">
          <img src={LogoDexco} alt="" className="logo-dexco" />
        </a>
        </ContainerFinalFooter>

        <ContainerLogos>
          <a href="https://www.deca.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoDeca} alt="Deca" className="logo" />
          </a>

          <a href="https://www.ceramicaportinari.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoPortinari} alt="Portinari" className="logo" />
          </a>

          <a href="https://www.hydra-corona.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoHydra} alt="Hydra" className="logo" />
          </a>

          <a href="https://www.duratexmadeira.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoDuratex} alt="Duratex" className="logo" />
          </a>

					<a href="https://www.castelatto.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoCastelatto} alt="Castelatto" className="logo" />
          </a>

          <a href="https://www.ceusa.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoCeusa} alt="Ceusa" className="logo" />
          </a>

          <a href="https://www.durafloor.com.br" target="_blank" rel="noreferrer noopener">
            <img src={LogoDurafloor} alt="Durafloor" className="logo" />
          </a>
        </ContainerLogos>
        <div className='footer-lgpd'>
          <button id='link-modal' onClick={openModal}>Política de Privacidade</button>
          <span> | </span>
          <button onClick={window.showConsentPreferencesPopup}>Preferências de Cookie</button>
        </div>
      </ContainerFooter>
      
      <ContainerModal style={{display: isModalVisible ? 'block' : 'none' }}>
        <ContentModal>
          <CloseOutlined onClick={closeModal} className="close" />
          <PrivacyPolicy/>
        </ContentModal>
      </ContainerModal>
    </>
  )
}