const states = [
  { 'value':'AC', 'state':'Acre' },
  { 'value':'AL', 'state':'Alagoas' },
  { 'value':'AP', 'state':'Amapá' },
  { 'value':'AM', 'state':'Amazonas' },
  { 'value':'BA', 'state':'Bahia' },
  { 'value':'CE', 'state':'Ceará' },
  { 'value':'DF', 'state':'Distrito Federal' },
  { 'value':'ES', 'state':'Espírito Santo' },
  { 'value':'GO', 'state':'Goiás' },
  { 'value':'MA', 'state':'Maranhão' },
  { 'value':'MT', 'state':'Mato Grosso' },
  { 'value':'MS', 'state':'Mato Grosso do Sul' },
  { 'value':'MG', 'state':'Minas Gerais' },
  { 'value':'PA', 'state':'Pará' },
  { 'value':'PB', 'state':'Paraíba' },
  { 'value':'PR', 'state':'Paraná' },
  { 'value':'PE', 'state':'Pernambuco' },
  { 'value':'PI', 'state':'Piauí' },
  { 'value':'RJ', 'state':'Rio de Janeiro' },
  { 'value':'RN', 'state':'Rio Grande do Norte' },
  { 'value':'RS', 'state':'Rio Grande do Sul' },
  { 'value':'RO', 'state':'Rondônia' },
  { 'value':'RR', 'state':'Roraima' },
  { 'value':'SC', 'state':'Santa Catarina' },
  { 'value':'SP', 'state':'São Paulo' },
  { 'value':'SE', 'state':'Sergipe' },
  { 'value':'TO', 'state':'Tocantins' },
]

export default states;