import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .span-confirmation {
    width: 600px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 200px;
    text-align: center;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }

    span {
      font-size: 18px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 23px;
  margin-top: 20px;
  font-weight: bold;
`;