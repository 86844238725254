import { LoadingOutlined } from "@ant-design/icons"
import { Skeleton, Spin, Progress, Button } from "antd"
import jwtDecode from "jwt-decode"
import { useEffect } from "react"
import { useState } from "react"
import { FiArrowLeftCircle } from "react-icons/fi"
import { Link } from "react-router-dom"
import { ContainerButtons, ContainerSpin } from "../../pages/HomeLogged/style"
import Instaladores from "../../assets/logo-instaladores-branco.png"
import api from "../../services/api"
import { Container, ContainerCourses, Course } from "./style"

export function CoursesLogged() {

  const token = JSON.parse(localStorage.getItem('token'))
  const jwtToken = jwtDecode(token.access_token)
  const [userName, setUserName] = useState()
  const id = jwtToken.idExternal
  const [loading, setLoading] = useState(true)
  const [loadingCourses, setLoadingCourses] = useState(true)
  const cpf = localStorage.getItem('cpf')
  const [loadingToEad, setLoadingToEad] = useState(false)
  const [showCourses, setShowCourses] = useState([])
  const [userLastName, setUserLastName] = useState()
  const [profession, setProfession] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function getCourses() {
      const { data } = await api.get(`/courses`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })

      const coursesProgress = await api.get(`/courses-progress?user_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })

      const progress = data.map(item => ({
        ...item,
        progress:
          coursesProgress.data.length ?
            coursesProgress.data.find(progress => progress.curso_id === item.courseId)?.progresso
            : undefined
      }))

      setShowCourses(progress)
      setLoadingCourses(false)
    }

    async function getUser() {
      const responseApi = await api.get(`/users?id=${id}&cpf=${cpf}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })
      const occupation = responseApi.data.occupation.occupation.occupation
      const otherOccupation = responseApi.data.occupation.occupation.otherOccupation
      setUserName(responseApi.data.firstName);
      setUserLastName(responseApi.data.lastName);
      setProfession(occupation ? occupation : otherOccupation);
      setLoading(false)
    }

    getUser()
    getCourses()
  }, [cpf, id, token?.access_token])

  async function redirectCourse(courseId) {
    setLoadingToEad(true)
    const { data } = await api.get(`/courses-access?user_id=${id}&course_id=${courseId}`, {
      headers: {
        Authorization: `Bearer ${token?.access_token}`
      }
    })
    window.open(data.link, '_blank')
    setLoadingToEad(false)
  }

  async function redirectTest(courseTestId) {
    setLoadingToEad(true);
    const { data } = await api.get(`/courses-access?user_id=${id}&course_id=${courseTestId}`, {
      headers: {
        Authorization: `Bearer ${token?.access_token}`
      }
    })
    window.open(data.link, '_blank')
    setLoadingToEad(false)
  }

  const antIcon = (
    <LoadingOutlined
      rotate={90}
      spin
    />
  );

  return (
    <>
      <Container>
        <div className='page-header'>
          <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
            <h2 className='user-name'>{userName}</h2>
            <h2 className='user-last-name'>{userLastName}</h2>
            <Link to='/edit-user'>
              <button className='btn-user-edit'>Editar dados</button>
            </Link>
            <h3 className='user-profession'>{profession}</h3>
          </Skeleton>
        </div>
        <h1 className='page-title'>Cursos</h1>
        <Link to="/home">
          <h2 className='new-content'>
            <FiArrowLeftCircle size={17} className='icon-arrow' />
            Voltar para tela inicial
          </h2>
        </Link>
        <ContainerCourses>
          <Skeleton paragraph={{ rows: 3 }} loading={loadingCourses} active>
            {showCourses.map((course, index) =>
              <Course key={`course-item-${index}`}>
                <div className='course-item'>
                  {course.release ?
                    <div className='course-image'>
                      <span>Novas Aulas</span>
                      {course.image ?
                        <img src={course.image} alt="Curso" />
                        :
                        <div className='default-image'>
                          <h1>{course.courseTitle}</h1>
                          <img className='logo' src={Instaladores} alt="Logo Clube Instaladores" />
                        </div>
                      }
                    </div>
                    :
                    <div className='course-image'>
                      <img src={course.image} alt="Curso" />
                    </div>
                  }
                  <h2>{course.courseTitle}</h2>
                  <div className='information'>
                    {course.description ?
                      <p className='container-desc'>
                        <b>Descrição: </b>
                        {course.description}
                      </p>
                      :
                      <p className='container-desc'>
                        <b>Descrição: </b>
                        Descrição ainda não disponivel.
                      </p>
                    }
                    {Number(course.progress) !== 100 ?
                      <p className='test'>
                        <b>Prova: </b>
                        Para realizar a prova, é necessário concluir o curso em andamento.
                      </p>
                      :
                      <p className='test'>
                        <b>Prova: </b>
                        Sua prova está disponivel para ser feita.
                      </p>
                    }
                    <b>Progresso do curso</b>
                    <div className="container-progress">
                      <Progress
                        percent={Number(course.progress || 0)}
                        showInfo={false}
                        strokeColor={"#00A860"}
                        className='progress'
                      />
                      <span className="percentage-progress">
                        Andamento: {Number(course.progress) ? Number(course.progress.split(".")[0]) : "0"}% Concluído
                      </span>
                    </div>
                    <ContainerButtons>
                      {Number(course.progress) === 100 ?
                        <><Button className='access-test' onClick={() => redirectTest(course.courseTest.courseId)}>
                          Acessar a Prova
                        </Button>
                          <Button className='access-course' onClick={() => redirectCourse(course.courseId)}>
                            Acessar o Curso
                          </Button>
                        </>
                        :
                        <Button className='access-course' onClick={() => redirectCourse(course.courseId)}>
                          Acessar o Curso
                        </Button>
                      }
                    </ContainerButtons>
                  </div>
                </div>
              </Course>
            )}
          </Skeleton>
        </ContainerCourses>
      </Container>
      {loadingToEad ?
        <ContainerSpin>
          <Spin indicator={antIcon} />
        </ContainerSpin>
        : null
      }
    </>
  )
}