import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  max-height: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;

  .page-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 34px;
  }
  .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 200px);
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    color: #1D1D1D;
    @media (max-width:767px) {
      max-width: initial;
    }
  }
  .user-last-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 200px);
    margin: 0;
    font-size: 32px;
    margin-left: 10px;
    font-weight: 700;
    color: #1D1D1D;
    @media (max-width:767px) {
      max-width: initial;
    }
  }
  .btn-user-edit {
    width: 160px;
    height: 35px;
    background-color: #000000;
    border: 1px solid #000000;
    color: #fff;
    margin: 0 0 0 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
    @media (max-width:767px) {
      margin: 5px 0 0;
      order: 3;
    }
  }
  .user-profession {
    width: 100%;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    color: #1D1D1D;
  }

  .page-title {
    margin: 40px 0 5px;
    font-size: 24px;
    font-weight: 700;
    color: #1D1D1D;
  }

  .icon-arrow {
    margin-right: 8px;
    height: 23px;
  }

  .new-content {
    font-weight: bold;
    height: 20px;
    text-decoration: underline;
    letter-spacing: 1px;
    margin-bottom: 50px;
    display: flex;
    align-items: end;
    margin-top: 15px;
  }

  .extra-content-item {
    width: 330px;
    height: auto;
    margin-bottom: 60px;
    a {
      display: flex;
      height: 200px;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #000;
      &:hover {
        .icon {
          transform: scale(1.1);
        }
      }
    }
    .icon {
      border-radius: 50%;
      border: 2px solid #000;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      padding-left: 5px;
      box-sizing: border-box;
      overflow: hidden;
      transition: transform 0.5s ease;
      svg {
        font-size: 40px;
        color: #000;
      }
    }
    .title {
      color: #1D1D1D;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      letter-spacing: 1px;
      margin: 16px 2px 8px;
    }
    .description {
      font-weight: 400;
      font-size: 12px;
      color: #1D1D1D;
      margin: 8px 0;
    }
  }
`

export const ContainerCourses = styled.div`
  width: 100%;
  min-height: 200px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media(max-width: 880px) {
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (max-width: 912px) {
    display: flex;
    gap: 80px;
    flex-wrap: wrap;
  }
`
export const Course = styled.div`
  .percentage-progress {
    position: absolute;
    z-index: 99;
    margin-left: 10px;
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin: auto;
    margin-top: 2px;
    margin-left: 10px;
  }

  .container-progress {
    display: flex;
    align-items: center;
  }

  .course-item {
    width: 360px;
    height: 510px;
    background-color: #F4F4F6;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 60px;

    u {
      cursor: pointer;
    }

    @media (max-width: 880px) {
      max-width: 83vw;
      height: 535px;
      margin-bottom: 0;
      margin-left: 5px;
    }
  }

  .course-image {
    span {
      width: 110px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      margin-top: 10px;
      margin-left: 15rem;
      float: left;
      background-color: black;
      color: white;
      padding-top: 2px;
      font-size: 14px;

      @media (max-width: 880px) {
        margin-left: calc(100% - 120px);
      }
    }

    img {
      width: 358px;
      height: 190px;
      border-top-left-radius: 19px;
      border-top-right-radius: 20px;

      @media (max-width: 880px) {
        width: 100%;
      }
    }

    .default-image {
      width: 358px;
      height: 190px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: #1C1C1C;

      @media (max-width: 880px) {
        width: inherit;
      }
      
      h1 {
        color: white;
        font-size: 23px;
        padding-top: 40px;
        margin-left: 15px;
        font-weight: bold;
        height: 100px;
      }

      .logo {
        width: 150px;
        height: 90px;
        margin-left: 200px;
        margin-top: -5px;

        @media (max-width: 880px) {
          height: 70px;
          margin-left: calc(100% - 150px);
        }
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
    color: black;
    margin-left: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    
    @media (max-width: 880px) {
      font-size: 17px;
      margin-left: 10px;
      margin-top: 10px;
      width: 290px;
      height: 30px;
    }
  }

  .information {
    color: black;
    margin-left: 15px;
    height: 240px;

    p {
      margin-bottom: 10px;
      line-height: 21px;
    }

    .test {
      margin-bottom: 30px;
      height: 40px;

      @media (max-width: 880px) {
        height: 60px;
        margin-bottom: 20px;
      }
    }

    .container-desc {
      height: 90px;

      @media (max-width: 880px) {
        height: 100px;
      }
    }

    @media (max-width: 800px) {
      margin-bottom: 8px;
      margin-left: 10px;
      height: 196px;
    }
  }

  .ant-progress-bg {
    height: 24px !important;
  }

  .ant-progress-inner {
    background-color: #D9D9D9;
  }

  .progress {
    width: 330px;

    @media (max-width: 880px) {
      max-width: 75vw;
    }

    b {
      letter-spacing: 1px;
    }
  }
`