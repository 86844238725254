import { NotFound } from "../components/NotFound";
import { RegisterConfirmation } from "../components/RegisterConfirmation";
import { RegisterNewPassword } from "../components/RegisterNewPassword";
import { RegisterUser } from "../components/RegisterUser";
import { SuccessNewPassword } from "../components/SuccessNewPassword";
import { SuccessRegister } from "../components/SuccessRegister";
import { ActiveToken } from "../pages/ActiveToken";
import { Courses } from "../pages/Courses";
import { SendForgotPassword } from "../pages/ForgotPassword";
import { HomeLogged } from "../pages/HomeLogged";
import PrivateRoute from "../utils/PrivateRoute";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { EditUser } from "../pages/EditUser";
import { DeleteUser } from "../components/DeleteUser";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Contact } from "../pages/Contact";
import { Videos } from "../pages/Videos";
import { CoursesLogged } from "../pages/CoursesLogged";
import { useState } from "react";
import { init as initApm } from '@elastic/apm-rum';

function NavRoutes() {
	initApm({
		serviceName: 'clube-instaladores-web', 
		serverUrl: process.env.REACT_APP_APM_URL,
		serviceVersion: '0.1',
		environment: process.env.REACT_APP_APM_ENV
	})
	const isEnterprise = process.env.REACT_APP_IS_ENTERPRISE === "true" ? true : false;
	const [isLogged, setIsLogged] = useState(false)

	return (
		<Router>
			<Header isUserLogged={isLogged} />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="*" element={<NotFound />} />
				<Route exact path="/courses" element={<Courses />} />
				<Route exact path="/contact" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><Contact /></GoogleReCaptchaProvider>} />
				<Route exact path="/register" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><Register /></GoogleReCaptchaProvider>} />
				<Route exact path="/register-user" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><RegisterUser /></GoogleReCaptchaProvider>} />
				<Route exact path="/active" element={<ActiveToken />} />
				<Route exact path="/register-confirmation" element={<RegisterConfirmation />} />
				<Route exact path="/success-register" element={<SuccessRegister />} />
				<Route exact path="/login" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><Login setIsLogged={setIsLogged} /></GoogleReCaptchaProvider>} />
				<Route exact path="/forgot-password" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><SendForgotPassword /></GoogleReCaptchaProvider>} />
				<Route exact path="/register-new-password" element={<RegisterNewPassword />} />
				<Route exact path="/success-delete-user" element={<DeleteUser />} />
				<Route exact path="/success-new-password" element={<SuccessNewPassword />} />
				<Route exact path="/home" element={<PrivateRoute ><HomeLogged /></PrivateRoute>}></Route>
				<Route exact path="/videos-logged" element={<PrivateRoute ><Videos /></PrivateRoute>}></Route>
				<Route exact path="/courses-logged" element={<PrivateRoute ><CoursesLogged /></PrivateRoute>}></Route>
				<Route exact path="/edit-user" element={<PrivateRoute >{<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_KEY_RECAPTCHA} useEnterprise={isEnterprise}><EditUser /></GoogleReCaptchaProvider>}</PrivateRoute>}></Route>
			</Routes>
			<Footer />
		</Router>
	);
}

export default NavRoutes;