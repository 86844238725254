import { PrivacyPolicyWrap } from './style'
import DexcoSignature from '../../assets/dexco-assinatura.png'

export function PrivacyPolicy() {
  return(
    <PrivacyPolicyWrap>
      <h3 className="mt-0">PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS NO MUNDO D</h3>
      <p>
        Os sites da DEXCO conectam você às diversas soluções em suas linhas de negócio tais como Duratex Madeira, Durafloor, Ceusa, Portinari, DEXperience, Hydra, Castelatto, Deca e Revestimentos Cerâmicos e projetos de sustentabilidade e inovação desenvolvidos pelas marcas DEXCO. Um ecossistema de possibilidades que chamamos de Mundo D!
      </p>
      <p>
        Ao interagir conosco online alguns dados pessoais seus podem ser coletados ou fornecidos por você. Esta Política de Privacidade faz parte do nosso compromisso de transparência e de uso ético dos dados pessoais: aqui você encontra os detalhes sobre como utilizamos seus dados pessoais.
      </p>
      <p>
        Esta política traz ainda os seus direitos como titular de dados e dispõe de um Sistema de Gestão de Privacidade (SGP) próprio, facilitado e gratuito, por onde administra todas as permissões, solicitações tratadas e finalidades para coleta e gestão de dados pessoais de nossos clientes, bem como todos os assuntos descritos abaixo, tal como nossas Políticas de Cookies.
      </p>

      <h3>DEXCO S.A.</h3>
      <p>
        Endereço: Av. Paulista - 1938 - Bela Vista <br/>
        São Paulo – SP CEP: 01310-942 <br/>
        Telefone: (11) 3179-7733 <br/>
        CNPJ: 97.837.181/0001-47 <br/>
        Inscrição Estadual: 114.578.650.114
      </p>

      <h3>DADOS PESSOAIS NO MUNDO D</h3>
      <p>Vide abaixo para saber mais sobre a utilização de dados pessoais em nossos sites.</p>
      <div className="text-center"><img src={DexcoSignature} alt="Dexco Assinatura" className="img-fluid"/></div>

      <h3>DEXCO</h3>
      <div className="box">
        <h4>DEXCO</h4>
        <p>https://www.dex.co/pt/intro</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seu e-mail para se cadastrar na Newsletter Mundo D; nome, e-mail, empresa, telefone, área (da empresa), comentário para entrar em contato no Fale Conosco; e compartilhar nosso conteúdo em suas redes sociais (Facebook, G+, LinkedIn)
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para enviar as Newsletters Mundo D solicitadas por você; responder ao seu contato no Fale Conosco e permitir que você compartilhe nosso conteúdo diretamente de nosso site para as suas redes sociais.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Clube Duratex</h4>
        <p>www.clubeduratex.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          E-mail, CPF, celular, senha, código de confirmação, nome, sobrenome, data de nascimento, se trabalha em marcenaria, profissão, estado, cidade, sexo, foto e complemento do endereço [CEP, nome da rua, número, complemento, bairro] de marceneiros, hobbistas, profissionais de arquitetura, revendas, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para se autenticar, se cadastrar e editar seus dados na plataforma, bem como participar do Programa de Relacionamento, que permite: acesso à sua área logada e obtenção de acesso e controle de seus dados pessoais e de sua pontuação, registro e envio de notas fiscais, informações sobre pontos que já resgatou em prêmios, catálogos disponíveis, campanhas e promoções ativas, regulamento, FAQ e Fale Conosco, atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões, confirmação automática caso determinado CPF, e-mail e celular, já tenham sido cadastrados anteriormente, impedindo a continuidade do cadastro em duplicidade, confirmação automática junto à empresa Serpro se o CPF consta como válido para a continuidade do cadastro, e preenchimento automático dos dados de endereço, por meio da inclusão do CEP, via integração com a empresa Via CEP.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Agência Base Digital: Acesso à base da Dexco para desenvolvimento e suporte da plataforma do Clube Duratex;<br/>
          - Azure e Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Google Cloud e/ou Amazon Web Services [EUA, Países da Ásia e/ou União Europeia]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud]; <br/>
          - Premmiar [1]: Integração com o catálogo de prêmios da Premmiar, para avaliação da pontuação, resgate e envio de prêmios pela empresa;<br/>
          - Premmiar [2]: Conhecimento e/ou tratativas do atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões, sempre que necessário;<br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco;<br/>
          - Serpro: Confirmação automática junto à empresa se o CPF consta como válido para a continuidade do cadastro;<br/>
          - Via CEP: Integração com a empresa Via CEP para preenchimento automático dos dados de endereço, por meio da inclusão do CEP; e<br/>
          - Zendesk as a Service: Condução das tratativas do atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Duratex Madeira</h4>
        <p>www.duratexmadeira.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          E-mail, nome, CPF, telefone, celular, assunto, descrição, tipo de contato [reclamação, consulta, informação técnica, sugestão, solicitação, elogio, dúvida técnica e solicitação de catálogo], endereço, número, CEP, cidade, bairro, estado, arquivos, área de atuação e tipo de projeto de arquitetos, marceneiros, designers de interiores, designers de móveis, construtoras, indústrias de móveis, revendedores de madeira, bem como consumidores finais, colaboradores Dexco, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para se cadastrar no recebimento de novidades sobre a empresa e lançamentos de produtos sendo que, a partir desse relacionamento, outras estratégias são lançadas para criação e manutenção de relacionamentos com os públicos de interesse, abordando temas diversificados, como: informações sobre produtos e programas de relacionamento, materiais educativos e envio de pesquisas, para receber atendimento sobre esclarecimento de eventuais dúvidas, reclamações e sugestões e para recebimento de comunicações e pontuações de forma automatizada aos usuários com base em suas interações para avaliação do nível de engajamento e qualidade de classificação de seu perfil.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Agência Mestre: Acesso à plataforma da RD Station para auxílio na operação, como atuar nas estratégias de marketing e importar e-mails no Facebook;<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Facebook: Leitura e cruzamento de dados e criação de público de acordo com o seu perfil do Facebook. A partir disso, o titular pode receber anúncios direcionados;<br/>
          - Google Cloud [EUA e Países da União Europeia]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Prestadores de Serviços [Assistências Técnicas Autorizadas]: Assistências em casos de avarias, reclamações etc.;<br/>
          - RD Station: Armazenamento da base de clientes cadastrados, bem como automação estratégias de marketing, envio de e-mail marketing, criação de landing pages e formulários, lead scoring;<br/>
          - Salesforce: Armazenamento de informações e tratativas do atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco. 
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Imagine</h4>
        <p>www.imagine.dex.co</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Matrícula, e-mail, senha [CPF], nome completo, data de nascimento, imagem, telefone, unidade de negócio, local, área, função, biografia, foto, pontuação, celular, operadora do celular, endereço de entrega, CEP, rua, número, complemento, ponto de referência, bairro, cidade, estado, destinatário e dados de cartão de crédito de colaboradores Dexco.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para pré-cadastro, fazer login e editar os dados em seu cadastro, permitindo que: tenha acesso à plataforma, consiga fazer login, bem como editar seus dados pessoais, para envio de ideias, sugestões e melhorias nos processos da empresa, passando pela avaliação do gestor responsável, e possibilitando o recebimento de pontos via plataforma Imagine, com posterior resgate de prêmios, para identificação do participante no ranking de pontuações e para o resgate de prêmios.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services e Azzure [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Braspag: Meio de pagamento, bem como para realização do débito no cartão de crédito do colaborador, caso queira complementar a pontuação para o resgate;<br/>
          - FCamara: Acesso e fornecimento de informações e relatórios à Área de Negócio Dexco responsável pelo processo;<br/>
          - Parceiros Premmiar: Entrega e emissão de nota fiscal. Cada parceiro possui uma regra específica sendo que, maiores esclarecimentos podem ser encontrados na Política de Privacidade da empresa Premmiar, responsável pelo respectivo compartilhamento. Poderão ser compartilhados maior ou menor quantidade de dados pessoais, a depender do parceiro;<br/>
          - Premmiar: Controle da pontuação e recarga de créditos para que o colaborador consiga resgatar os prêmios a que tiver direito; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Jeito de Ser e Fazer Dexco</h4>
        <p>https://jeitodeserefazerdexco.com.br/</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome, matrícula, fotos e vídeos de colaboradores Dexco.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para pré-cadastro, fazer login e participar da disseminação da nova cultura Dexco, podendo: ter acesso aos jogos, aos vídeos dos Vice-Presidentes, Diretores e demais responsáveis pela disseminação dos comportamentos e informações relacionadas, fazer upload de suas fotos como participante e fazer parte do álbum de figurinhas Dexco.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Be Comunica: responsável pelas ações de cultura junto à Dexco, pela gestão do trabalho de seus parceiros no projeto e também pelo armazenamento dos dados pessoais coletados; e <br/>
          - Parceiro Be Comunica: empresa contratada pela Be Comunica, responsável pela criação da Landing Page e também pelo armazenamento dos dados pessoais coletados.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Portal de Fornecedores</h4>
        <p>portalfornecedores.DEXCO.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seus dados de nome e sobrenome, função, telefone/celular, departamento, fax e e-mail vinculados ao cadastro de uma empresa fornecedora.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para cadastrar você como contato de sua empresa.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Execução de contrato e procedimentos preliminares a ele e legítimo interesse.</p>
      </div>

      <div className="box">
        <h4>Loja Dexco</h4>
        <p>www.loja.dex.co</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome completo, CPF, e-mail, telefone, endereço de entrega, endereço residencial, endereço comercial, CEP, dados de pagamento [boleto, cartão de crédito, PIX, dados bancários], número do pedido, nota fiscal, gravação de ligação telefônica [voz e conteúdo], dados informados pelo consumidor na reclamação, anexo opcional enviado pelo CS [podem conter outros dados pessoais], RG, data de nascimento, endereço IP, sexo, anexos enviados pelo cliente via plataforma de SAC, histórico de consumo, escolaridade, signo, nacionalidade, dados de terceiros [dependentes, nome da mãe, histórico de contatos familiares], renda presumida, informações sobre sociedade, dados de veículo e histórico CCF [cadastro de cheque sem fundo].
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Por meio da empresa Infracommerce, responsável pelo setup da loja virtual e operação, utilizamos seus dados pessoais para: comercialização de produtos da marca Dexco através das lojas virtuais, aprovação dos meios de pagamento, identificação e separação do pedido no estoque, faturamento/emissão de Nota Fiscal no SEFAZ, etiquetamento da encomenda para envio ao cliente, entrega do pedido preparado à transportadora, posicionamento do consumidor com relação à entrega e acionamento das áreas de apoio, suporte para trocas e devoluções, abertura de demandas junto às transportadoras para posicionamento e solução de problemas com entrega ou com produto, suporte à logística quanto à alteração de informação dos pedidos, suporte quanto a problemas no pedido ou produto, tratativas na plataforma Reclame Aqui para solução de problemas, tratativas de ações diferenciadas solicitadas pelo cliente, reembolso de valores motivados por devoluções acionado via SAC, conciliação das vendas de Marketplace para identificar se todas as vendas serão recebidas, emissão e fornecimento de vales para consumidores realizarem troca de produtos detentores de vício, análise de solicitações de estorno e reembolso para aprovação, análise de risco e prevenção à fraude e consolidação de dados para resultado e acompanhamento mensais de receitas de serviços prestados [fechamento].
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros:<br/>
          - Infracommerce: empresa responsável pelo setup da loja virtual e operação. Para viabilizar a operação, a Infracommerce compartilha ainda os dados pessoais com as empresas que seguem abaixo, ou similares contratadas por ela, para as finalidades descritas, no que se refere ao envio do pedido de compra e à logística reversa, se houver:<br/>
          - Sellers e empresas de serviços de cloud, como Amazon Web Services: comercialização de produtos da marca Dexco através das lojas virtuais e armazenamento dos dados pessoais tratados;<br/>
          - Gateways, Adquirentes, Wallets [sistemas de meios de pagamento - Adyen, Mercado Pago, Rede, Cielo, Paymee, PicPay, Munipague, AME, entre outras], empresas de análise de fraude e de serviços de cloud, como  Amazon Web Services: Controle e maximização da aprovação dos meios de pagamento através de acompanhamento de status de pagamento nas adquirentes e armazenamento dos dados pessoais tratados;<br/>
          - Sellers, Secretaria da Fazenda (SEFAZ), transportadoras e empresas de serviços de cloud, como Amazon Web Services: identificação, separação do pedido no estoque, faturamento/emissão de Nota Fiscal na SEFAZ, etiquetamento da encomenda para envio ao cliente, entrega do pedido preparado à transportadora e armazenamento dos dados pessoais tratados e armazenamento dos dados pessoais tratados;<br/>
          - Sellers e Transportadoras: posicionamento do consumidor com relação à entrega e acionamento das áreas de apoio, suporte para trocas e devoluções, abertura de demandas junto às transportadoras para posicionamento e solução de problemas com entrega ou com produto, suporte à logística quanto à alteração de informação dos pedidos, suporte quanto a problemas no pedido ou produto, tratativas na plataforma Reclame Aqui para solução de problemas e tratativas de ações diferenciadas solicitadas pelo cliente;<br/>
          - Gateways, Adquirentes, Wallets [sistemas de meios de pagamento - Adyen, Mercado Pago, Rede, Cielo, Paymee, PicPay, Munipague, AME, entre outras] e empresas de análise de fraude: reembolso de valores motivados por devoluções acionado via SAC, conciliação das vendas de Marketplace para identificar se todas as vendas serão recebidas, emissão e fornecimento de vales para consumidores realizarem troca de produtos detentores de vício, análise de solicitações de estorno e reembolso para aprovação, análise de risco e prevenção à fraude e consolidação de dados para resultado e acompanhamento mensais de receitas de serviços prestados [fechamento].
        </p>

        <h4>Fundamento legal:</h4>
        <p>
          Execução de contrato e procedimentos preliminares a ele, cumprimento de obrigação legal ou regulatória e exercício regular de direitos em processo judicial, administrativo ou arbitral.
        </p>
      </div>

      <div className="box">
        <h4>Portal de Fornecedores</h4>
        <p>https://portalfornecedores.duratex.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seus dados de nome e sobrenome, função, telefone/celular, departamento, fax e e-mail vinculados ao cadastro de uma empresa fornecedora.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para cadastrar você como contato de sua empresa.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Execução de contrato e procedimentos preliminares a ele e legítimo interesse</p>
      </div>

      <div className="box">
        <h4>Portal de Atendimento de Suprimentos</h4>
        <p>https://helpsupply.DEXCO.com.br/</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seus dados de nome e sobrenome, função, telefone/celular, departamento, fax e e-mail vinculados ao cadastro de uma empresa fornecedora.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para cadastrar você como contato de sua empresa.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Execução de contrato e procedimentos preliminares a ele e legítimo interesse.</p>
      </div>

      <h3>Durafloor</h3>
      <div className="box">
        <h4>Durafloor</h4>
        <p>www.durafloor.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          E-mail, nome, CPF, telefone, celular, assunto, descrição, tipo de contato [reclamação, consulta, informação técnica, sugestão, solicitação, elogio, dúvida técnica e solicitação de catálogo], endereço, número, CEP, cidade, bairro, estado, arquivos, área de atuação e tipo de projeto de arquitetos, marceneiros, designers de interiores, designers de móveis, construtoras, indústrias de móveis, revendedores de madeira, bem como consumidores finais, colaboradores Dexco, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para se cadastrar no recebimento de novidades sobre a empresa e lançamentos de produtos sendo que, a partir desse relacionamento, outras estratégias são lançadas para criação e manutenção de relacionamentos com os públicos de interesse, abordando temas diversificados, como: informações sobre produtos e programas de relacionamento, materiais educativos e envio de pesquisas, para receber atendimento sobre esclarecimento de eventuais dúvidas, reclamações e sugestões e para recebimento de comunicações e pontuações de forma automatizada aos usuários com base em suas interações para avaliação do nível de engajamento e qualidade de classificação de seu perfil.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Agência Mestre: Acesso à plataforma da RD Station para auxílio na operação, como atuar nas estratégias de marketing e importar e-mails no Facebook;<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Facebook: Leitura e cruzamento de dados e criação de público de acordo com o seu perfil do Facebook. A partir disso, o titular pode receber anúncios direcionados;<br/>
          - Google Cloud [EUA e Países da União Europeia]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Prestadores de Serviços [Assistências Técnicas Autorizadas]: Assistências em casos de avarias, reclamações etc.;<br/>
          - RD Station: Armazenamento da base de clientes cadastrados, bem como automação estratégias de marketing, envio de e-mail marketing, criação de landing pages e formulários, lead scoring;<br/>
          - Salesforce: Armazenamento de informações e tratativas do atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Deca</h3>
      <div className="box">
        <h4>Deca</h4>
        <p>www.deca.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome, e-mail, CPF, telefone, assunto, descrição, tipo de contato [reclamação, consulta, informação técnica, sugestão, solicitação, elogio, dúvida técnica e solicitação de catálogo], endereço, número, CEP, cidade, bairro, estado, complemento, arquivos, perfil [consumidor final, arquiteto, estudante, construtora, designer de interiores, designer de móveis, indústria de móveis, instalador, marceneiro, revendedor], geolocalização, obtida via latitude e longitude; ou inclusão dos seguintes dados para consulta no Google Maps, obtendo a localização via latitude e longitude: CEP ou endereço, bairro, cidade, estado e país de arquitetos, designers de interiores, designers de móveis, construtoras, lojistas, vendedores, bem como consumidores finais, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões, para localização de revendedores exclusivos e de assistências técnicas mais próximos do usuário.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Google Maps: Caso seja consultado determinado CEP ou endereço, trazendo informações de latitude e longitude do titular;<br/>
          - Prestadores de Serviços [Assistências Técnicas Autorizadas]: Assistências em casos de avarias, reclamações etc.;<br/>
          - Salesforce: Armazenamento de informações e tratativas do atendimento ao usuário para esclarecimento de eventuais dúvidas, reclamações e sugestões; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <div className="box">
        <h4>Clube Instaladores</h4>
        <p>https://www.clubeinstaladores.com.br/</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seu nome, sobrenome, CPF, data de nascimento, profissão, e-mail, celular, endereço completo, CEP, estado e cidade para se cadastrar no programa de relacionamento Clube Instaladores para fins de participação, para receber comunicações relacionadas [como novidades e informações sobre o programa, site, cadastro de notas fiscais, cursos, prêmios, benefícios e eventos] e comunicações sobre as marcas e produtos do grupo Dexco via E-mail, SMS ou WhatsApp. Além disso, seus dados poderão ainda ser compartilhados com órgãos governamentais para atendimento às determinações de autoridades competentes, como a SECAP – Secretaria de Avaliação, Planejamento, Energia e Loteria.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para cadastro de instaladores hidráulicos, maridos de aluguel, mestres de obras, entre outras pessoas interessadas, em conteúdo sobre hidráulica no programa de relacionamento Clube Instaladores para fins de participação; para recebimento de comunicações relacionadas [como novidades e informações sobre o programa, site, cadastro de notas fiscais, cursos, prêmios, benefícios e eventos] e comunicações sobre as marcas e produtos do grupo Dexco via E-mail, SMS ou WhatsApp e para compartilhamento com órgãos governamentais.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Secap: atendimento às determinações de autoridades competentes; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento [exceto quanto ao atendimento à SECAP, que nos baseamos no fundamento de cumprimento de obrigação legal]</p>
      </div>

      {/* <div className="box">
        <h4>Deca Clube</h4>
        <p>www.decaclub.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seu nome, endereço, cidade, estado, bairro, CEP, sexo, redes sociais, telefone, data de nascimento, e-mail e CPF para se cadastrar e participar da plataforma. Também informar perfil (estudante, profissional, jornalista).
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para enviar comunicações de Marketing que sejam de seu interesse.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div> */}

      <h3>Hydra</h3>
      <div className="box">
        <h4>Hydra Corona</h4>
        <p>www.hydra-corona.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seu nome, endereço, cidade, estado, bairro, CEP, sexo, data de nascimento, e-mail e CPF para se cadastrar e participar da plataforma.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para retornar atendimento e entrar em contato para oferta de serviços e promoções.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>DEXperience</h3>
      <div className="box">
        <h4>DEXperience</h4>
        <p>https://www.dexperience.com.br/</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          CPF, e-mail, nome completo, gênero, data de nascimento, instagram, celular, telefone, estágio da carreira, tempo de atuação no mercado, perfil, número de registro [CAU, ABD, CREA], CEP, estado, cidade, bairro, rua, número, complemento e senha de profissionais e estudantes de arquitetura, engenharia, designer de interiores, paisagistas, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para se cadastrar, fazer login e editar seus dados no cadastro caso deseje acessar a plataforma para participação do Programa de Relacionamento das marcas Dexco [Duratex, Ceusa, Portinari e Deca], bem como manter seu cadastro atualizado e para que sejam utilizados em réguas de relacionamento de e-mails automáticos [recebimento de token via SMS ou E-mail + e-mail de boas-vindas, além de outras comunicações].
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Amazon Web Services [Salesforce Marketcloud - EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Marketdata: Empresa responsável pela plataforma, bem como pelo armazenamento dos dados pessoais tratados;<br/>
          - Salesforce: Armazenamento de dados pessoais, bem como envio de comunicações;<br/>
          - Secap: Órgão que atua na fiscalização e autorização de campanhas promocionais; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Ceusa</h3>
      <div className="box">
        <h4>Ceusa</h4>
        <p>https://www.ceusa.com.br/essence/cadastro</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Você pode nos fornecer seu nome, endereço, cidade, estado, bairro, CEP, sexo, data de nascimento, e-mail e CPF para se cadastrar e participar da plataforma.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para retornar atendimento e entrar em contato para oferta de serviços e promoções.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Portinari</h3>
      <div className="box">
        <h4>Portinari</h4>
        <p>www.ceramicaportinari.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome e email.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Para enviar comunicações de Marketing que sejam de seu interesse.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Premio Arque</h3>
      <div className="box">
        <h4>Premio Arque</h4>
        <p>www.premioarque.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome completo, e-mail, telefone, data de nascimento, CPF, profissão e número do CAU [se arquiteto] e ABD [se designer], endereço, CEP, rua, número, complemento, cidade, estado, senha, nome do responsável pelo projeto e CPF do responsável pelo projeto, que podem ser arquitetos, empresas com arquiteto responsável e escritórios de arquitetura.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para se cadastrar, fazer login e editar seus dados na plataforma e para desenvolver projetos envolvendo produtos Duratex, com premiação para cada uma das categorias existentes.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Secap: Entrega dos prêmios aos ganhadores de cada categoria; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Meu Piso Ideal</h3>
      <div className="box">
        <h4>Meu Piso Ideal</h4>
        <p>https://durafloor.com.br/meupisoideal/</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome, e-mail, foto e mensagem de arquitetos, marceneiros, designers de interiores, designers de móveis, construtoras, indústrias de móveis, revendedores de madeira, bem como consumidores finais, colaboradores Dexco, entre outros.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para recebimento de e-mail com os resultados das simulações do piso e informações sobre onde comprar.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Amazon Web Services [EUA e Outros Países]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Roomvo: Simulação do piso, bem como armazenamento dos dados pessoais coletados; e <br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco. 
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Duratex Inspira</h3>
      <div className="box">
        <h4>Duratex Inspira</h4>
        <p>www.duratexinspira.com.br</p>

        <h4>Quais dados pessoais são utilizados?</h4>
        <p>
          Nome completo, e-mail, username do Instagram, nome e imagem do MoodBoard criado por arquitetos.
        </p>

        <h4>Para que utilizamos seus dados pessoais?</h4>
        <p>
          Você pode nos fornecer os dados pessoais mencionados para publicação do MoodBoard na Galeria e Rede Social do Usuário e para recebimento de comunicação de que o MoodBoard será publicado e, caso o usuário forneça o seu consentimento, para posterior envio de comunicações.
        </p>

        <h4>Com quem compartilhamos seus dados pessoais?</h4>
        <p>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br/>
          - Agência Base Digital: Acesso à base da Dexco para desenvolvimento, suporte e garantia da plataforma do Duratex Inspira;<br/>
          - Amazon Web Services [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud];<br/>
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco; e <br/>
          - Whatsapp: Compartilhamento do MoodBoard pelo próprio usuário, caso assim deseje.
        </p>

        <h4>Fundamento legal:</h4>
        <p>Consentimento</p>
      </div>

      <h3>Conservação e Eliminação de dados</h3>
      <p>
        Manteremos seus dados pessoais armazenados enquanto for necessário, para prestar os serviços e para fins legítimos e essenciais, tais como para manter o correto funcionamento e rastreabilidade das informações, tomar decisões empresariais acerca de funcionalidades e mailing com base em dados, cumprir os fins legais, e resolver disputas. Conservamos alguns dos seus dados pessoais enquanto permanecer ativo no portal.
      </p>
      <p>
        Quando solicitado, eliminaremos os seus dados pessoais de modo que não o identifiquem, exceto se for legalmente permitido ou obrigatório manter determinados dados pessoais, incluindo situações como as seguintes:
      </p>
      <ul>
        <li>
          Se formos obrigados a manter os dados pessoais para as nossas obrigações jurídicas, fiscais, de auditoria e contabilidade, iremos reter os seus dados pessoais necessários pelo período exigido pela legislação aplicável;
        </li>
        <li>
          Sempre que necessário para os nossos legítimos interesses comerciais, como a prevenção contra fraudes ou para manter a segurança do nosso ambiente e demais usuários do portal/site.
        </li>
      </ul>

      <h3>Registro de acesso aos sites e aplicativos do Mundo D</h3>
      <p>
        A DEXCO coleta e armazena pelo período necessário os dados de acesso aos seus sites de aplicativos [data, hora e IP] para cumprimento de obrigação legal imposta pelo Marco Civil da Internet [art. 15, Lei nº 12.965/2014].
      </p>

      <h3>Cookies</h3>
      <p>
        Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita o Site. A DEXCO utiliza cookies para facilitar o uso e melhor adaptar seus sites e aplicativos aos interesses e necessidades de seus usuários.
      </p>

      <table>
        <tbody>
          <tr>
            <th>Tipos de Cookies</th>
            <th>O que eles fazem?</th>
          </tr>
          <tr>
            <td>Necessários</td>
            <td>
              Esses cookies são essenciais para que os sites e aplicativos carreguem corretamente e permitem que você navegue e faça uso de todas as funcionalidades disponíveis.
            </td>
          </tr>
          <tr>
            <td>Desempenho</td>
            <td>
              Esses cookies nos ajudam a entender como os visitantes interagem com sites e aplicativos, fornecendo informações sobre as áreas visitadas, o tempo de visita e quaisquer problemas encontrados, como mensagens de erro.
            </td>
          </tr>
          <tr>
            <td>Funcionais</td>
            <td>
              Esses cookies permitem que sites e aplicativos se lembrem das escolhas dos usuários, para proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários utilizem a integração com redes sociais, campos para comentários, entre outros.
            </td>
          </tr>
          <tr>
            <td>Marketing</td>
            <td>
              Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos usuários. Podem ser utilizados para apresentar publicidade direcionada ou limitar sua veiculação.
            </td>
          </tr>
        </tbody>
      </table>

      <p className="mt-3">
        A qualquer momento, você poderá rever a forma de utilização dos cookies, utilizando para tanto as configurações de seu navegador de preferência. Para mais informações sobre como proceder em relação à gestão dos cookies nos navegadores, disponibilizamos os links abaixo:
      </p>
      <ul>
        <li>Internet Explorer: https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies</li>
        <li>Mozilla Firefox: https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</li>
        <li>Google Chrome: https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR</li>
        <li>Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac</li>
      </ul>
      <p>
        Caso você não permita a utilização de alguns cookies, certos serviços poderão não funcionar de maneira ideal [por exemplo, a utilização de serviços que requerem o login].
      </p>
      <p>
        Abaixo está a lista dos principais cookies que utilizamos:
      </p>

      <table>
        <tbody>
          <tr>
            <th>Tipos de Cookies</th>
            <th>Objetivo e finalidade</th>
          </tr>
          <tr>
            <td>Cookies analíticos</td>
            <td>
              Esses cookies são usados para estimar público visitante, identificar padrões de uso e acelerar as pesquisas.
            </td>
          </tr>
          <tr>
            <td>Cookies do Google Analytics</td>
            <td>
              <p>
                Esses cookies são usados para coletar informações sobre como os visitantes usam os sites da Dexco. A Dexco e as empresas de seu conglomerado econômico usam as informações para compilar relatórios e para ajudar a melhorar o site. Os cookies coletam informações de forma anônima, incluindo o número de visitantes do site, de onde os visitantes vieram até o site e as páginas visitadas.
              </p>
              <p>
                O Google armazena as informações coletadas pelo cookie em servidores nos Estados Unidos. O Google também pode transferir essas informações para terceiros quando exigido por lei ou quando tais terceiros processarem as informações em nome do Google. O Google não associará seu endereço IP a nenhum outro dado mantido pelo Google.
              </p>
              {/* <p>Clique aqui para uma visão geral da privacidade no Google.</p> */}
            </td>
          </tr>
          <tr>
            <td>Cookies de sessão</td>
            <td>
              Esses cookies são usados para manter sua transação. Geralmente seus cookies de sessão expiram quando seu navegador é fechado. O uso dos cookies de sessão reduz a necessidade de a Dexco transferir suas informações pela Internet.
            </td>
          </tr>
          <tr>
            <td>Web Beacons</td>
            <td>
              Os Web beacons são arquivos de imagem nítidos [arquivos .gif transparentes] que permitem à Dexco rastrear as interações do usuário. A Dexco pode usar web beacons em mensagens de e-mail ou newsletters para determinar se as mensagens foram abertas ou se os links foram acessados. Eles também permitem rastrear as ações do cliente nos websites para adaptar os serviços da Dexco a você. O conteúdo personalizado é influenciado por sua interação com a Dexco, incluindo as páginas visualizadas, as ofertas pelas quais você demonstra interesse e as respostas fornecidas para as perguntas da pesquisa.
            </td>
          </tr>
          <tr>
            <td>Cookies de funcionalidade</td>
            <td>
              Esses cookies são usados para reconhecer visitantes repetidos no site e permitir que o site se lembre das escolhas que você fez [como nome de usuário, idioma ou a região em que você está]. Eles costumam ser usados para registrar informações específicas de navegação [incluindo a maneira como você chega ao site, as páginas que você visualiza, as opções selecionadas, as informações inseridas e o caminho que você percorre no site] em relação a um perfil de cliente individual.
            </td>
          </tr>
          <tr>
            <td>Cookies de preferência do país</td>
            <td>
              Esses cookies são usados para registrar sua preferência de país. Eles permitem que a página do país selecionado seja apresentada ao visitante sem a reinserção da preferência do país durante cada visita ao site.
            </td>
          </tr>
          <tr>
            <td>Cookies de preferência de idioma</td>
            <td>
              Esses cookies são usados para gravar o idioma preferido. Eles permitem que o idioma preferido seja exibido para o visitante sem a reinserção da preferência de idioma durante cada visita ao site.
            </td>
          </tr>
          <tr>
            <td>Cookie do Sistema de Gerenciamento de Conteúdo</td>
            <td>
              Indicam a maneira como o site está sendo visualizado [Live Site / Design Mode, etc.]
            </td>
          </tr>
          <tr>
            <td>Gerenciamento de Autenticação</td>
            <td>
              Contém suas informações de ticket de autenticação.
            </td>
          </tr>
          <tr>
            <td>Cookies de Terceiros</td>
            <td>
              Também desenvolvemos relacionamentos com parceiros cuidadosamente selecionados e monitorados para ajudar na entrega de um site de qualidade. Alguns desses parceiros podem definir cookies durante a sua visita para acompanhar o desempenho de campanhas de marketing e/ou cumprir obrigações contratuais com a Dexco. Embora esses cookies não armazenem nenhum dado pessoal relacionado a você, a Dexco não tem acesso ou controle sobre os cookies e tecnologias semelhantes que os seus parceiros usam.
            </td>
          </tr>
          <tr>
            <td>Cookies de rastreamento</td>
            <td>
              Os cookies são usados por esses parceiros para acompanhar o desempenho de campanhas de marketing de recrutamento.
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Compartilhamento de dados pessoais</h3>
      <p>
        Além dos aspectos detalhados anteriormente, a DEXCO pode compartilhar dados pessoais com parceiros comerciais para a prestação dos serviços oferecidos por meio de seus sites e aplicativos, com autoridades e demais interessados, para proteção de seus interesses em qualquer tipo de conflito, incluindo ações judiciais; mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para esta requisição. Todos os parceiros da DEXCO garantem adotar medidas de segurança técnicas e administrativas para proteção dos dados pessoais.
      </p>

      <h3>Segurança da informação e dos dados pessoais</h3>
      <p>
        A DEXCO adota medidas de segurança para manter a confidencialidade, a integridade e a disponibilidade das informações e dos dados pessoais em seus sites e aplicativos, incluindo as diretrizes sobre padrões de segurança estabelecidas no Decreto Regulamentador do Marco Civil da Internet [Decreto nº 8.771/2016], tais como métodos padrão e de mercado para criptografar os dados coletados; proteção contra acesso não autorizado a seus sistemas; e compromisso de confidencialidade de todos os que tratam dados pessoais em nome da DEXCO.
      </p>
      <p>
        Apesar de todas as medidas de segurança adotadas, é importante relembrar que nenhum site ou aplicativo é totalmente seguro. Por isso, incentivamos que você também tome medidas de segurança para proteção de seus dados, como por exemplo, manter seus logins e senha confidenciais.
      </p>

      <h3>Seus direitos</h3>
      <p>
        As leis brasileiras de privacidade e proteção de dados pessoais asseguram a você, titular de dados pessoais, uma série de garantias que o colocam no controle das informações que você fornece a nós. Como modo de informá-lo sobre os seus Direitos e sobre como exercê-los, apresentamos a seguir tudo aquilo que se aplica à Você.
      </p>
      <p>
        A Dexco conta com profissionais treinados para atender às suas dúvidas e requisições. Para exercer esses direitos, você pode solicitá-los à Dexco, acessando no rodapé da página inicial de seus sites o link "Política de Privacidade". Vá até a seção Seus Direitos, e clique no link <a href="https://privacy-central.securiti.ai/#/dsr/e2cf4abb-3064-4a33-a9d6-b93caafcda6e">Portal de Abertura de Solicitações de Titulares (DSR)</a>.
      </p>
      <p>
        Importante ressaltar, que pais, mães ou responsáveis legais poderão exercer os direitos em nome de crianças ou de adolescentes, nos termos da legislação aplicável.
      </p>
      <p>
        Conforme previsto na LGPD, você pode exercer os seguintes direitos com relação aos seus dados pessoais:
      </p>
      <ul>
        <li>
          Confirmação da existência de tratamento: este direito permite que você possa requisitar e receber uma confirmação sobre a existência de coleta, armazenamento, compartilhamento ou qualquer tipo de tratamento a partir de seus dados pessoais;
        </li>
        <li>
          Acesso aos dados: este direito permite que você requisite e receba uma cópia gratuita e em formato acessível dos dados pessoais tratados por nós;
        </li>
        <li>
          Correção de dados quando incompletos, inexatos ou desatualizados: caso você identifique alguma incorreção ou imprecisão nos seus dados pessoais que tratamos, poderá requerer que os retifiquemos;
        </li>
        <li>
          Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei: este direito permite que seja requerida a anonimização, o bloqueio ou a eliminação dos dados pessoais da nossa base. Todos os dados coletados serão anonimizados, bloqueados ou eliminados dos nossos servidores e arquivos físicos quando assim for possível e requisitado por você e, em conformidade também com o atendimento a outras legislações vigentes;
        </li>
        <li>
          Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial: a Dexco permite a você ou a terceiro nomeado, obter acesso aos dados pessoais que são tratados por nós em formato estruturado e interoperável;
        </li>
        <li>
          Eliminação dos dados tratados com consentimento do titular, exceto nas hipóteses de guarda legal e outras dispostas em Lei: caso você nos tenha fornecido o seu consentimento para uma finalidade de tratamento, você ainda pode requisitar a eliminação dos dados pessoais que armazenamos até então;
        </li>
        <li>
          Obtenção de informações sobre as entidades públicas e privadas com as quais a DEXCO compartilhou seus dados;
        </li>
        <li>
          Peticionar em relação aos seus dados contra o controlador perante a Autoridade Nacional de Proteção de Dados;
        </li>
        <li>
          Revogação do consentimento: você tem o direito de revogar o seu consentimento ou, se for o caso, de não nos fornecê-lo e ser informado sobre as consequências da negativa. Nestes casos, o não consentimento não acarretará prejuízo na utilização de produtos ou serviços da Dexco;
        </li>
        <li>
          Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade: você tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado dos dados pessoais pela Dexco e de receber informações claras e adequadas a respeito dos critérios utilizados na decisão, desde que essas informações não violem qualquer segredo comercial da Dexco; e
        </li>
        <li>
          Oposição ao tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento e em desconformidade com a lei: você também tem o direito de se opor a determinadas finalidades de tratamento de dados pessoais, como, por exemplo, o envio de publicidade, newsletter ou novas ofertas.
        </li>
      </ul>
      <p>
        No caso do site "Jeito de Ser e Fazer Dexco", em casos de colaboradores DEXCO Colômbia, os direitos podem ser solicitados diretamente ao endereço de e-mail: <a href="mailto:datospersonales@dex.co">datospersonales@dex.co</a>.
      </p>
      <p>
        Ainda, te informamos a seguir como atuamos quando recebermos a sua requisição. Em primeiro lugar, nosso time de Privacidade irá percorrer algumas etapas antes de analisar sua solicitação. Essas etapas incluem um cadastro inicial do titular de dados, uma etapa de validação de canal de comunicação e uma etapa de validação de identidade do titular.
      </p>
      <p>Essas etapas visam garantir que:</p>
      <ul>
        <li>Exista um meio válido para comunicação entre você e a Dexco;</li>
        <li>O solicitante é realmente quem diz ser, prevenindo que seus dados não sejam divulgados a qualquer pessoa que não tenha legitimidade para recebê-los e;</li>
        <li>A Dexco tenha em mãos os dados corretos para realizar a pesquisa interna a seu respeito.</li>
      </ul>

      <p>
        Percorridas as etapas iniciais acima, a Dexco irá protocolar e analisar sua solicitação e poderá responder-lhe de duas maneiras, determinando que:
      </p>
      <ul>
        <li>Seu pedido é lícito e legítimo, devendo, portanto, ser acatado, ou;</li>
        <li>Seu pedido foi negado ou parcialmente aceito e, por determinadas razões, não poderá ser acatado ou será acatado parcialmente.</li>
      </ul>
      <p>
        Não se preocupe, pois, mesmo em caso de negativa, iremos informar a você os motivos pelos quais seu pedido não foi aprovado e todas as nossas respostas serão sempre enviadas pelo mesmo meio pelo qual você nos contatou.
      </p>
      <p>
        Essas medidas são necessárias considerando a nossa preocupação com as informações que tratamos, sobretudo, as informações sobre crianças e adolescentes.
      </p>
      <p>
        Caso seu pedido seja aceito, faremos o possível para contatar os nossos fornecedores e parceiros comerciais que podem ter acesso aos seus Dados Pessoais para que eles também retifiquem, eliminem ou exerçam qualquer outro Direito que você requisitou. No entanto, infelizmente não podemos garantir o real exercício desses direitos por esses Terceiros, que utilizam, divulgam e protegem os dados pessoais de acordo com suas respectivas políticas.
      </p>
      <p>
        Poderemos armazenar e manter, em forma de registro, um histórico das requisições de direitos que você fez para que possamos, se necessário, apresentá-lo às autoridades competentes como prova de que respondemos os pedidos em tempo hábil e da maneira adequada, conforme a legislação estabelece.
      </p>
      <p>
        Recebida a sua requisição, o nosso time retornará a você uma resposta dentro do prazo legal, a partir do recebimento da requisição.
      </p>
      <p>
        Caso sejam necessários esclarecimentos ou mais informações sobre o seu pedido, podemos enviar a você alguns questionamentos para que possamos responder à sua requisição de maneira satisfatória, de modo que os prazos poderão estar suspensos, desde o envio dos nossos questionamentos, até o recebimento da sua resposta.
      </p>
      <p>
        Em caso de não atendimento a algum dos direitos acima mencionados, ou em caso de qualquer violação à legislação de proteção de dados pessoais, o time de Privacidade da DEXCO está pronto para te atender, entre em contato com <a href="mailto:privacidade@dex.co">privacidade@dex.co</a>.
      </p>

      <h3>Encarregado pelo Tratamento de Dados Pessoais</h3>
      <p>
        A DEXCO tem em sua estrutura um Encarregado pelo Tratamento de Dados Pessoais, precisamente para garantir que tenhamos sempre as melhores diretrizes e orientações sobre o tratamento de dados pessoais. Ele é o canal de comunicação sobre os temas da Privacidade e da Proteção de Dados Pessoais com as autoridades competentes e com você. Por isso, caso tenha alguma dúvida ou solicitação, entre em contato no e-mail <a href="mailto:dpo@dex.co">dpo@dex.co</a>.
      </p>
      <p>
        Caso tenha dúvidas, comentários ou sugestões relacionados a esta Política, você pode entrar em contato com o time de privacidade da Dexco por este mesmo endereço de e-mail.
      </p>

      <h3>Atualizações desta Norma</h3>
      <p>
        A DEXCO manterá essa Política sempre atualizada, para que ela reflita a realidade sobre o tratamento de seus dados pessoais em nossos sites e aplicativos.
      </p>

      <p className="text-right">Última atualização em 25/08/2022.</p>
    </PrivacyPolicyWrap>
  )
}