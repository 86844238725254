import moment from 'moment';

export const payloadRegisterAdapter = (payload) => {
  const formatDate = moment(payload.birthDate, "DD/MM/YYYY").format();
  return  { 
    ...payload, 
    cpf: payload.cpf.replace(/\.|-/gm,''),
    birthDate: formatDate,
    login: {
      email: payload.email,
      password: payload.password,
      confirmPassword: payload.confirmPassword
    },
    contact: {
      cel: payload.cel.replace(/[^0-9]+/g, ""),
    },
    agreements: {
      register: payload.register,
      receiveCommunication: payload.receiveCommunication
    },
    cep: payload.cep.replace("-", ""),
    occupation: {
      occupation: payload.otherOccupation ? "" : payload.occupation,
      otherOccupation: payload.occupation === "Outros" ? payload.otherOccupation : ""
    },
    password: undefined,
    confirmPassword: undefined,
    email: undefined,
    cel: undefined,
    register: undefined,
    receiveCommunication: undefined,
    otherOccupation: undefined
  }
}

export const updateUserForm = (user) => {
  const formatDate = moment(user.birthDate).format("DD/MM/yyyy");
  return {
    address: user.address,
    birthDate: formatDate,
    cep: user.cep,
    city: user.city,
    cel: user.contact.cel,
    cpf: user.cpf,
    district: user.district,
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender,
    email: user.login.email,
    number: user.number,
    occupation: user.occupation.occupation.otherOccupation ? "Outros" : user.occupation.occupation.occupation,
    otherOccupation: user.occupation.occupation.otherOccupation,
    complement: user.complement,
    state: user.state,
    agreements: {
      register: user.register,
      receiveCommunication: user.receiveCommunication
    },
  }
}