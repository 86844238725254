import { Button, Divider, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Container, ContainerCode, ContainerPhone, Title } from "./style";
import api from "../../services/api";
import { useState } from "react";

export function TokenPhone({ contact, cpf }) {

  const key = 'updatable';
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [loadingResend, setLoadingResend] = useState(false);
  const navigate = useNavigate();
  
  function resend() {
    form
    .validateFields()
    .then(async (values) => {
      setLoadingResend(true);
      api.post('/users/token/cel', { cpf: cpf.replace(/\.|-/gm,'')} )
      .then(response => {
        message.success({ content: 'Reenviamos o token para o seu número!', key, duration: 2 });
        setLoadingResend(false);
      }).catch(error => {
        message.error({ content: 'Código inválido', key, duration: 2 });
        setLoadingResend(false);
      })
    })
  }

  function onChange(event) {
    setToken(event.target.value);
  }

  function onFinish(value) {
    form
    .validateFields()
    .then(async (values) => {
      setLoading(true);
      api.put('/users/token/cel', { token: value.code, cpf: cpf.replace(/\.|-/gm,'') })
      .then(response => {
        setLoading(false);
        localStorage.setItem('oldUser', JSON.stringify(response.data))
        navigate('/register-user');
      }).catch(error => {
        message.error({ content: 'Código inválido', key, duration: 2 });
        setLoading(false);
      })
    })
  }

  return(
    <>
      <Container>
        <Title>
          Cadastro
        </Title>
        <div className="span-register">
          <span>
            Um token foi enviado para o seu celular para validação do cadastro
          </span>
        </div>
        <Divider />
        <ContainerPhone>
          <h2>{contact}</h2>
        </ContainerPhone>
        <div className="type-code">
          <p>Digite o código de 6 dígitos que enviamos para você.</p>
        </div>
        <ContainerCode>
          <Form className="form" onFinish={onFinish}>
            <Form.Item 
              name="code"
              className="label required"
              label="Código"
              rules={[{ required: true, message: 'Insira o código' }]}
              >
              <Input
                className="input"
                type="code"
                maxLength={6}
                onChange={onChange}
              />
            </Form.Item>
            <div className="container-submit">
              <Button
                className="btn-send" 
                htmlType="submit"
                loading={loading}
                disabled={!token}
              >
                Enviar
              </Button>
              <Button
                className="btn-send" 
                htmlType="submit"
                onClick={resend}
                loading={loadingResend}
              >
                Reenviar
              </Button>
            </div>
          </Form>
        </ContainerCode>  
      </Container>
    </>
  )
}