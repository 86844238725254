import { useState } from 'react';
import { ForgotPassword } from '../../components/ForgotPassword';
import { NotificationSendEmailForgotPassword } from '../../components/NotificationSendEmailForgotPassword';

export function SendForgotPassword() {
  const [payload, setPayload] = useState({ });

  const handlePayload = (values) => {
    setPayload({ ...payload, ...values });
  }

  return (
    <>
      { 
        !payload.cpf ? 
          <ForgotPassword handleForm={handlePayload} /> :
          <NotificationSendEmailForgotPassword user={payload} />
      }
    </>
  )
}