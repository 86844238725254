import { Container, Title } from "./style";
import { Divider } from "antd";
import { useEffect } from "react";

export function RegisterConfirmation() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
      <Container>
        <Title>
          E-mail de confirmação enviado para sua caixa de entrada
        </Title>
        <Divider />
        <div className="span-confirmation">
          <span>
            Você irá receber um e-mail de confirmação no e-mail cadastrado!
          </span>
        </div>
      </Container>
    </>    
  )
}