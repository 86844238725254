import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;

  hr.solid {
  border-top: 1px solid #bbb;
  width: 85%;
  margin-top: 20px;
}
`

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`

export const ContainerStart = styled.div`
  width: 85%;
  height: 380px;
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  @media(max-width: 820px) {
    width: 100vw;
    height: 100px;
  }
`

export const Text = styled.div`
  font-size: 25px;
  width: 410px;
  height: 263px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  letter-spacing: 1px;
  padding-right: 40px;
  padding-top: 60px;

    @media(max-width: 820px) {
      width: 100vw;
      font-size: 24px;
      text-align: center;
      padding: 0;
      margin-top: 200px;
    }

    @media(max-width: 900px) {
      width: 90vw;
    }

    @media(max-width: 912px) {
      padding-top: 10px;
      padding-right: 30px;
    }

    @media(min-width: 1900px) {
      width: 450px;
      margin-left: 120px;
      padding-top: 80px;
    }
`

export const Image = styled.div`
  width: 660px;
  height: 426px;
  margin-top: 70px;

  @media(min-width: 912px) {
    width: 50vw;
  }

  img {
    width: 635px;

    @media(min-width: 912px) {
      width: 55vw;
    }
  }

    img {
      @media(max-width: 820px) {
        width: 0;
      }

      @media(min-width: 1900px) {
        width: 540px;
        height: 340px;
      }
    }

    @media(max-width: 820px) {
      width: 0;
    }
`
export const ContainerCourse = styled.div`
  max-width: 1100px;
  margin: auto;
	margin-bottom: 2em;
	flex-wrap: wrap;

  .ant-row {
    justify-content: start;
    
    @media(min-width: 912px) {
      width: 90vw;
      justify-content: flex-start;
    }

    @media(min-width: 1200px) {
      margin-top: 120px;
    }

    @media(min-width: 1400px) {
      margin-top: 210px;
    }


    @media(min-width: 1900px) {
      width: 1150px;
      margin: 0;
    }
  }

  .ant-col-xs-16 {
    max-width: none;
  }

  @media(max-width: 820px) {
    justify-content: center;
    margin-top: 100px;
    width: 100vw;

    .ant-row{
      justify-content: center;
      margin: 0;
    }
  }
`

export const Card = styled.div`
  width: 340px;
	height: 630px;
	margin-top: 1em;
	margin-bottom: 1em;
  background-color: #F4F4F5;
  display: flex;
  justify-content: center;

  @media(max-width: 820px) {
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 320px;
    height: 215px;
    margin-top: 10px;
  }

  .course-number-title {
    width: 352px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 270px;
    position: absolute;
  }

  .course-number {
    width: 30px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
  }

  .course-title {
    margin-left: 15px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .description {
    width: 310px;
    height: 300px;
    display: flex;
    justify-content: center;
    top: 320px;
    position: absolute;
  }

  p {
    letter-spacing: 0.3px;
    line-height: 22px;
    font-family: "Roboto";
  }

  .btn-know-more {
    width: 135px;
    height: 35px;
    display: flex;
    justify-content: center;
    left: 100px;
    top: 600px;
    padding: 0;
    border: 1px solid #000;
    cursor: pointer;
    font-size: 15px;
    align-items: center;
    position: absolute;
    color: black;
    background-color: #000;
    color: #fff;
      :hover{
        background: #F4F4F5;
        color: #000;
      }
  }
`