import { Container, ContainerButtons, ContainerSlide, ContainerSpin, Course, Video } from './style'
import Instaladores from "../../assets/logo-instaladores-branco.png"
import listExtraContent from '../../constants/extra-content'
import { Row, Col, Modal, Carousel, Skeleton, Spin, Progress, Button } from 'antd'
import { useEffect, useState } from "react"
import { FiArrowRightCircle } from "react-icons/fi"
import {
  CaretRightOutlined,
  LoadingOutlined,
  RightOutlined
} from '@ant-design/icons'
import jwtDecode from 'jwt-decode'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import { useRef } from 'react'

export function HomeLogged() {

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [modalTitle, setModalTitle] = useState()

  const token = JSON.parse(localStorage.getItem('token'))
  const jwtToken = jwtDecode(token.access_token)
  const [userName, setUserName] = useState()
  const [userLastName, setUserLastName] = useState()
  const [profession, setProfession] = useState()
  const [showCourses, setShowCourses] = useState([])
  const [courseTest, setCourseTest] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingCourses, setLoadingCourses] = useState(true)
  const [loadingToEad, setLoadingToEad] = useState(false)
  const id = jwtToken.idExternal
  const cpf = localStorage.getItem('cpf')

  const carouselRef = useRef(null)
  const carouselCourseRef = useRef(null)

  const width = window.innerWidth

  const showModal = () => {
    setIsModalVisible(true)
  };

  useEffect(() => {
    async function getCourses() {
      const { data } = await api.get(`/courses`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })

      const coursesProgress = await api.get(`/courses-progress?user_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })

      const progress = data.map(item => ({
        ...item,
        progress:
          coursesProgress.data.length ? coursesProgress.data.find(progress => progress.curso_id === item.courseId)?.progresso
            : undefined
      }))
      setShowCourses(progress)
      setCourseTest(coursesProgress.data)
      setLoadingCourses(false)
    }

    async function getUser() {
      const responseApi = await api.get(`/users?id=${id}&cpf=${cpf}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })
      const occupation = responseApi.data.occupation.occupation.occupation
      const otherOccupation = responseApi.data.occupation.occupation.otherOccupation
      setUserName(responseApi.data.firstName)
      setUserLastName(responseApi.data.lastName)
      setProfession(occupation ? occupation : otherOccupation)
      setLoading(false)
    }
    getUser()
    getCourses()
  }, [cpf, id, token?.access_token])

  async function redirectCourse(courseId) {
    setLoadingToEad(true);
    const { data } = await api.get(`/courses-access?user_id=${id}&course_id=${courseId}`, {
      headers: {
        Authorization: `Bearer ${token?.access_token}`
      }
    })
    window.open(data.link, '_blank')
    setLoadingToEad(false)
  }

  async function redirectTest(courseTestId) {
    setLoadingToEad(true);
    const { data } = await api.get(`/courses-access?user_id=${id}&course_id=${courseTestId}`, {
      headers: {
        Authorization: `Bearer ${token?.access_token}`
      }
    })
    window.open(data.link, '_blank')
    setLoadingToEad(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setModalContent('')
  };

  const videoIframe = (video) => {
    return (
      <iframe width="560" height="315"
        src={`https://www.youtube.com/embed/${video.videoId}?rel=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ width: '100%', height: '100%' }}>
      </iframe>
    )
  }

  const selectVideo = (event, video) => {
    event.preventDefault()
    setModalContent(videoIframe(video))
    setModalTitle(video.title)
    showModal()
  }

  const handleNext = () => carouselRef.current.next()

  const handleNextCourse = () => carouselCourseRef.current.next()

  const antIcon = (
    <LoadingOutlined
      rotate={90}
      spin
    />
  );

  return (
    <>
      <Container>
        <div className='page-header'>
          <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
            <h2 className='user-name'>{userName}</h2>
            <h2 className='user-last-name'>{userLastName}</h2>
            <Link to='/edit-user'>
              <button className='btn-user-edit'>Editar dados</button>
            </Link>
            <h3 className='user-profession'>{profession}</h3>
          </Skeleton>
        </div>
        <h1 className='page-title'>Aproveite nossos Conteúdos Extras</h1>
        <Link to="/videos-logged">
          <h2 className='new-content'>Ver todos os conteúdos
            <FiArrowRightCircle size={17} className='icon-arrow' />
          </h2>
        </Link>
        <ContainerSlide>
          <Row>
            <Col span={width > 800 ? 23 : 22}>
              <Carousel
                draggable
                slidesPerRow={width < 800 ? 1 : width <= 1080 ? 2 : 3}
                ref={carouselRef}
              >
                {listExtraContent.extraContent.map((item, index) =>
                  <Video key={`extra-content-item-${index}`}>
                    <div className='extra-content-item'>
                      {(item.videoExternalLink) ?
                        <a href={item.videoExternalLink} target='_blank' rel='noopener noreferrer'
                          style={{ backgroundImage: `url(${item.cover})` }} title={item.title}>
                          <span className='icon'><CaretRightOutlined /></span>
                        </a>
                        :
                        <a href='/#' onClick={(e) => selectVideo(e, item)}
                          style={{ backgroundImage: `url(${item.cover})` }} title={item.title}>
                          <span className='icon'><CaretRightOutlined /></span>
                        </a>}
                      <p className='title'>{item.title}</p>
                    </div>
                  </Video>
                )}
              </Carousel>
            </Col>
            <Col span={width < 800 ? 2 : 1} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
              <RightOutlined className='arrow' onClick={handleNext} />
            </Col>
          </Row>
        </ContainerSlide>

        <h1 className='page-title'>Cursos</h1>
        <Link to="/courses-logged">
          <h2 className='new-content'>Ver todos os cursos
            <FiArrowRightCircle size={17} className='icon-arrow' />
          </h2>
        </Link>
        <ContainerSlide>
          <Skeleton paragraph={{ rows: 3 }} loading={loadingCourses} active>
            <Row>
              <Col span={width > 800 ? 23 : 22}>
                <Carousel
                  draggable
                  slidesPerRow={width < 800 ? 1 : width <= 1080 ? 2 : 3}
                  ref={carouselCourseRef}
                >
                  {showCourses.map((course, index) =>
                    <Course key={`course-item-${index}`}>
                      <div className='course-item'>
                        {course.release ?
                          <div className='course-image'>
                            <span>Novas Aulas</span>
                            {course.image ?
                              <img src={course.image} alt="Curso" />
                              :
                              <div className='default-image'>
                                <h1>{course.courseTitle}</h1>
                                <img className='logo' src={Instaladores} alt="Logo Clube Instaladores" />
                              </div>
                            }
                          </div>
                          :
                          courseTest.find((item) => item.curso_tiulo === `Avaliação - ${course.courseTitle}`) ?
                            <div className='course-image'>
                              <span className='certificate'>Certificado Disponível</span>
                              {course.image ?
                                <div className='default-image'>
                                  <img className='image-course' src={course.image} alt="Curso" />
                                </div>
                                :
                                <div className='default-image'>
                                  <h1>{course.courseTitle}</h1>
                                  <img className='logo' src={Instaladores} alt="Logo Clube Instaladores" />
                                </div>
                              }
                            </div>
                            :
                            <div className='course-image'>
                              <img src={course.image} alt="Curso" />
                            </div>
                        }
                        <h2>{course.courseTitle}</h2>
                        <div className='information'>
                          {course.description ?
                            <p className='container-desc'>
                              <b>Descrição: </b>
                              {course.description}
                            </p>
                            :
                            <p className='container-desc'>
                              <b>Descrição: </b>
                              Descrição ainda não disponivel.
                            </p>
                          }
                          {Number(course.progress) !== 100 ?
                            <p className='test'>
                              <b>Prova: </b>
                              Para realizar a prova, é necessário concluir o curso em andamento.
                            </p>
                            :
                            Number(course.progress) === 100 && courseTest.find((item) => item.curso_tiulo === `Avaliação - ${course.courseTitle}`) ?
                              <p className='test'>
                                <b>Prova: </b>
                                Você já realizou sua prova e pode obter seu certificado online.
                              </p>
                              :
                              <p className='test'>
                                <b>Prova: </b>
                                Sua prova está disponivel para ser feita.
                              </p>
                          }
                          <b>Progresso do curso</b>
                          <div className="container-progress">
                            <Progress
                              percent={Number(course.progress || 0)}
                              showInfo={false}
                              strokeColor={"#00A860"}
                              className='progress'
                            />
                            <span className="percentage-progress">
                              Andamento: {Number(course.progress) ? Number(course.progress.split(".")[0]) : "0"}% Concluído
                            </span>
                          </div>
                          <ContainerButtons>
                            {Number(course.progress) === 100 && courseTest.find((item) => item.curso_tiulo === `Avaliação - ${course.courseTitle}`) ?
                              <><Button className='access-test' onClick={() => redirectTest(course.courseTest.courseId)}>
                                Prova Concluída
                              </Button>
                                <Button className='access-course' onClick={() => redirectCourse(course.courseId)}>
                                  Acessar o Curso
                                </Button>
                              </>
                              :
                              Number(course.progress) === 100 ?
                                <><Button className='access-test' onClick={() => redirectTest(course.courseTest.courseId)}>
                                  Acessar a Prova
                                </Button>
                                  <Button className='access-course' onClick={() => redirectCourse(course.courseId)}>
                                    Acessar o Curso
                                  </Button>
                                </>
                                :
                                <Button className='access-course' onClick={() => redirectCourse(course.courseId)}>
                                  Acessar o Curso
                                </Button>
                            }
                          </ContainerButtons>
                        </div>
                      </div>
                    </Course>
                  )}
                </Carousel>
              </Col>
              <Col span={width < 800 ? 2 : 1} style={{ marginTop: "150px", display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                <RightOutlined className='arrow' onClick={handleNextCourse} />
              </Col>
            </Row>
          </Skeleton>
        </ContainerSlide>

        <Modal title={modalTitle} className='modal-extra-content' destroyOnClose={true}
          visible={isModalVisible} onCancel={handleCancel} footer={null} centered>
          {modalContent}
        </Modal>

      </Container>
      {loadingToEad ?
        <ContainerSpin>
          <Spin indicator={antIcon} />
        </ContainerSpin>
        : null
      }
    </>
  )
}