const listExtraContent = {
  "extraContent": [
    {
      "title": "Caixa higienizadora",
      "cover": "https://i.ytimg.com/vi/GVR6KrG_QGY/hqdefault.jpg",
      "videoId": "GVR6KrG_QGY"
    },
    {
      "title": "Assento Smart",
      "cover": "https://i.ytimg.com/vi/70hFnMdskdU/hqdefault.jpg",
      "videoId": "70hFnMdskdU"
    },
    {
      "title": "Caixa desodorizadora",
      "cover": "https://i.ytimg.com/vi/5L1xayNBgfY/hqdefault.jpg",
      "videoId": "5L1xayNBgfY"
    },
    {
      "title": "Ducha higiênica universal",
      "cover": "https://i.ytimg.com/vi/Hm-29UFDd4M/hqdefault.jpg",
      "videoId": "Hm-29UFDd4M"
    },
    {
      "title": "Assentos",
      "cover": "https://i.ytimg.com/vi/D-vBkpvYqCE/hqdefault.jpg",
      "videoId": "D-vBkpvYqCE"
    },
    {
      "title": "Acionamento Touchless",
      "cover": "https://i.ytimg.com/vi/XlFoUNJ2n88/hqdefault.jpg",
      "videoId": "XlFoUNJ2n88"
    },
    {
      "title": "Tecnologia Total Clean",
      "cover": "https://i.ytimg.com/vi/f5JAapBP_SM/hqdefault.jpg",
      "videoId": "f5JAapBP_SM"
    },
    {
      "title": "Tecnologia Alta Performance",
      "cover": "https://i.ytimg.com/vi/BiAR-YvwnE8/hqdefault.jpg",
      "videoId": "BiAR-YvwnE8"
    },
    // {
    //   "title": "Assento Smart | Tecnologia | Canal Deca",
    //   "cover": "https://i.ytimg.com/vi/70hFnMdskdU/hqdefault.jpg",
    //   "videoId": "70hFnMdskdU"
    // },
    // {
    //   "title": "Caixa Desodorizadora | Tecnologia | Canal Deca",
    //   "cover": "https://i.ytimg.com/vi/5L1xayNBgfY/hqdefault.jpg",
    //   "videoId": "5L1xayNBgfY"
    // },
    // {
    //   "title": "Bacias Deca - Tecnologia Alta Performance",
    //   "cover": "https://i.ytimg.com/vi/BiAR-YvwnE8/hqdefault.jpg",
    //   "videoId": "BiAR-YvwnE8"
    // },
    // {
    //   "title": "Bacias Deca - Tecnologia Total Clean",
    //   "cover": "https://i.ytimg.com/vi/f5JAapBP_SM/hqdefault.jpg",
    //   "videoId": "f5JAapBP_SM"
    // },
    // {
    //   "title": "Bacias Deca - Acionamento de descarga Touchless",
    //   "cover": "https://i.ytimg.com/vi/XlFoUNJ2n88/hqdefault.jpg",
    //   "videoId": "XlFoUNJ2n88"
    // },
    // {
    //   "title": "Bacias Deca - Assentos",
    //   "cover": "https://i.ytimg.com/vi/D-vBkpvYqCE/hqdefault.jpg",
    //   "videoId": "D-vBkpvYqCE"
    // },
    // {
    //   "title": "Caixa Higienizadora | Tecnologia | Canal Deca",
    //   "cover": "https://i.ytimg.com/vi/GVR6KrG_QGY/hqdefault.jpg",
    //   "videoId": "GVR6KrG_QGY"
    // }
    // {
    //   "title": "Exemplo 03",
    //   "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    //   "cover": 'https://i.ytimg.com/vi/iIqfyE7J1Ks/hqdefault.jpg',
    //   "videoIdYouTube": "",
    //   "videoExternalLink": "https://www.youtube.com/embed/iIqfyE7J1Ks"
    // }
  ]
}

export default listExtraContent;
