import { Carousel } from "antd";
import BannerDicaMestre from '../../assets/banner-dica-de-mestre2.png';
import BannerVoceSabia from '../../assets/banner-voce-sabia2.png';
import { Banner, Container } from './style'

export function Home() {

	return(
    <>
      <Container>
        <Carousel autoplay autoplaySpeed={3000} draggable
          style={{width:'100%', overflowX:'hidden'}}>
            <Banner>
              <div className="banner-item banner-invert">
                <div className="content">
                  <div>
                    <p className="description">
                      <i>Você conhece a série <b>Você sabia?</b></i><br/>
                      Toda semana no Instagram oferecemos dicas rápidas sobre instalação e manutenção de produtos para ajudar no seu dia a dia.
                    </p>
                  
                    <a href="https://www.instagram.com/clubeinstaladores/" target="_blank" rel="noreferrer noopener" className="link">QUERO SEGUIR</a>
                    <p className="follow">
                      Siga o nosso  perfil <br/><b>@clubeinstaladores</b>
                    </p>
                  </div>
                </div>
                <div className="bg">
                  <img src={BannerVoceSabia} alt=""/>
                </div>
              </div>
            </Banner>
            <Banner>
              <div className="banner-item">
                <div className="bg">
                  <img src={BannerDicaMestre} alt=""/>
                </div>
                <div className="content">
                  <div>
                    <p className="description">
                      Acompanhe nossa série <b>Dica de Mestre</b> no Instagram e receba orientações técnicas e conteúdos que vão acelerar seu conhecimento em hidráulica.
                    </p>
                  
                    <a href="https://www.instagram.com/clubeinstaladores/" target="_blank" rel="noreferrer noopener" className="link">QUERO SEGUIR</a>
                    <p className="follow">
                      Siga o nosso  perfil <br/><b>@clubeinstaladores</b>
                    </p>
                  </div>
                </div>
              </div>
            </Banner>
        </Carousel>
      </Container>
    </>
	)
}