import { useSearchParams } from 'react-router-dom';
import { TokenPhone } from '../../components/TokenPhone';
import { TokenEmail } from '../../components/TokenEmail';

export function ActiveToken() {

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const contact = searchParams.get(type);
  const cpf = searchParams.get('cpf');

  return(
    <>
      {type === 'cel' ?
        <TokenPhone contact={contact} cpf={cpf} />
      :
        <TokenEmail contact={contact} cpf={cpf}/>
      }
    </>
  )
}