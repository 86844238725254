import { Container, Label, Link, Title } from "./style";

export function ContainerLinks({name, links}) {
  return(
    <Container>
      <Label htmlFor={name}> {name} </Label>
      <Title type="checkbox" name={name} id={name} />
      {links.map((link, index) => 
        <Link target="_blank" href={link.href} key={index} className="link">
          {link.name}
        </Link>
      ) }
    </Container>
  )
}