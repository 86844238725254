import { api } from '../../services/api';
import { 
  Container, 
  Title, 
  ContainerForm, 
  Items, 
  ContainerContacts, 
  Terms, 
  Authorizations, 
  ModalTerm 
} from './style';
import { Input, Form, Button, message, Divider  } from 'antd';
import IconEmail from '../../assets/icon-email.svg';
import IconWhatsapp from '../../assets/icon-whatsapp.svg';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function Contact() {

  const { TextArea } = Input;

  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const key = 'updatable';

  const [loading, setLoading] = useState(false);
  const [authorizeFirstTerm, setAuthorizeFirstTerm] = useState();
  const [messageErrorAuthorizeFirstTerm, setMessageErrorAuthorizeFirstTerm] = useState(false);

  function handleOpenModal() {
    document.getElementById("link-modal").click();
  }

  const cleanFields = () => {
    form.resetFields();
    setLoading(false);
  }

  async function onFinish(values) {
    const recaptcha = await executeRecaptcha();
    setLoading(true);
    form
    .validateFields()
    .then((values) => {
      const payload = {...values, recaptcha}
      if(payload && authorizeFirstTerm){
        message.loading({ content: 'Enviando...', key})
        api.post('/contact',payload)
          .then(response => {
            message.success({ content: 'Sua mensagem foi enviada com sucesso!', key, duration: 2 });
            cleanFields();
          }).catch(error => {
            message.error({ content: 'Error ao enviar sua mensagem, tente novamente!', key, duration: 2 });
            setLoading(false);
          })
        } else {
          setMessageErrorAuthorizeFirstTerm(true)
          message.error({ content: 'Error ao enviar sua mensagem, tente novamente!', key, duration: 2 });
          setLoading(false);
      }
    })
  }

  return(
    <>
      <Container>
        <Title>
          Contato
        </Title>
        <Divider />
        <div className="span-contact">
          <span>Em caso de dúvidas entre em contato conosco.</span>
        </div>
        <ContainerForm>
          <Items>
            <Form onFinish={onFinish} form={form} className="form">
              <Form.Item 
                name="name"
                className="label"
                label="NOME"
                rules={[{ required: true, message: 'Insira seu nome' }]}
              >
                <Input name='name' minLength={5} placeholder="Seu nome" className="input"/>
              </Form.Item>

              <Form.Item 
                name="email"
                className="label"
                label="EMAIL"
                rules={[{ required: true, message: 'Insira seu e-mail' }]}
              >
                <Input name="email" placeholder="Seu e-mail" className="input" type="email"/>
              </Form.Item>

              <Form.Item 
                name="subject"
                className="label"
                label="ASSUNTO"
                rules={[{ required: true, message: 'Insira o assunto' }]}
              >
                <Input name='subject' placeholder="Assunto de sua mensagem" minLength={5} className="input"/>
              </Form.Item>

              <Form.Item 
                name="message"
                className="label"
                label="MENSAGEM"
                rules={[{ required: true, message: 'Insira sua mensagem' }]}
              >
                <TextArea 
                  placeholder="Descreva o motivo do seu contato e como podemos ajudá-lo (a)"
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
              </Form.Item>
              <Terms>
                <Authorizations>
                  <label className="container-checkbox">
                    <input type="checkbox" onChange={(event) => setAuthorizeFirstTerm(event.target.checked)} name="register" className="checkbox-list-item" />
                    <span className="required-term" required>
                      Autorizo a coleta dos meus dados pessoais para que o time de atendimento do Clube Instaladores possa analisar/atender à minha solicitação.
                    </span>                  
                  </label>
                  {messageErrorAuthorizeFirstTerm ? 
                    <p>Caso você não concorde com a coleta dos seus dados pessoais para as finalidades descritas, não poderemos prosseguir com seu cadastro.</p>
                  : null }
                </Authorizations>
              </Terms>
              <ModalTerm>
                <p onClick={handleOpenModal}><b><u>Clique aqui</u></b> para acessar o nosso 
                <b> Aviso de Privacidade Clube Instaladores.</b></p>
              </ModalTerm>
            <ContainerContacts>
              <span>Ou se preferir, envie uma mensagem para</span>
              <div className="contact-email">
                <img src={IconEmail} alt="Email" className="icon-email"/>
                <a href="mailto:clubeinstaladores@dex.co" className='href-email'> clubeinstaladores@dex.co</a>
              </div>
              <div className="contact-whatsapp">
                <img src={IconWhatsapp} alt="Whatsapp" className="icon-whatsapp"/>
                <p className='whatsapp'>(11) 96495-8028</p>
              </div>
              <div className="schedule">
                <span>
                  De segunda a sexta-feira, das <br/>9h às 12h e das 14h às 18h
                </span>
              </div>
            </ContainerContacts>
                <div className="container-submit">
                  <Button 
                    className="btn-send" 
                    htmlType="submit" 
                    disabled={!authorizeFirstTerm || !form.setFieldsValue}
                    loading={loading}
                  >
                    ENVIAR
                  </Button>
                </div>
              </Form>
            </Items>
          </ContainerForm>
        </Container>
    </>
  )
}