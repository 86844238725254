import { Container, ContainerRegister, Title } from "./style";
import { Button, Divider, Form, message } from "antd";
import InputMask from 'react-input-mask';
import { useState } from "react";
import { checkCPF } from "../../utils/validation";
import api from "../../services/api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function ForgotPassword({ handleForm }) {
  const key = 'updatable';
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);

  async function onFinish(value) {
    const recaptcha = await executeRecaptcha();
    const payload = { ...value, recaptcha, cpf: value.cpf.replace(/\.|-/gm,'') };
    setLoading(true);
    form
    .validateFields()
    .then(async (values) => {
      message.loading({ content: 'Enviando...', key});
      if(payload && checkCPF(payload.cpf)){ 
        const response = await api.post('/users/forgot-password', payload);
        setLoading(false);
        handleForm({ ...payload, ...response.data });
      }
    }).catch((error) => {
      message.error({ content: 'Erro ao enviar email de redefinição de senha, tente novamente!', key, duration: 2 });
      setLoading(false);
    })
  }

  return(
    <>
      <Container>
        <Title>
          Esqueci a senha
        </Title>
        <Divider />
        <div className="span-forgot-password">
          <span>Gostaria de redefinir sua senha? Digite abaixo seu CPF.</span>
        </div>
        <ContainerRegister>
          <Form className="form" onFinish={onFinish} form={form}>
            <Form.Item 
              name="cpf"
              className="label"
              label="CPF"
              rules={[{ required: true, message: 'Insira seu cpf' }]}
            >
              <InputMask
                className="input"
                mask="999.999.999-99"
                placeholder="000.000.000-00"
              />
            </Form.Item>
            <div className="container-submit">
              <Button 
                className="btn-send" 
                htmlType="submit" 
                loading={loading}
              >
                ENVIAR
              </Button>
            </div>
          </Form>
        </ContainerRegister>
      </Container>
    </>
  )
}