import { Button, Divider, Form, message, Radio } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Container, ContainerRadio, Title } from "./style";

export function ChooseToken({ payload, user }) {

  const key = 'updatable';
  const [type, setType] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

   function handleSubmit(e) {
    setLoading(true);
    if(type === 'redirect') return navigate(`/contact`);
    
    const cpf = payload.cpf.replace(/\.|-/gm,'');
    form
      .validateFields()
      .then((values) => {
        api.post(`/users/token/${type}`, { cpf });
      setLoading(false);
      navigate(`/active?type=${type}&email=${user.email}&cel=${user.cel}&cpf=${payload.cpf}`);
      }).catch((error) => {
        message.error({ content: 'Error ao enviar token, tente novamente!', key, duration: 2 });
        setLoading(false);
      })
  }
  
  async function onChange(e) {
    switch (e.target.value) {
      case 1:
        setType('email')
        break;
        case 2:
          setType('cel')
          break;
          default:
            setType('redirect')
            break;
          }
  };

  return(
    <Container>
      <Form form={form} onFinish={handleSubmit}>
      <Title>
        Cadastro
      </Title>
      <Divider />
      <div className="span-register">
        <span>
        Para garantir sua segurança, enviaremos um <b>código para você.</b>
        <br></br>Escolha qual a forma de envio do <b>Token:</b>
        </span>
      </div>
      <ContainerRadio>
        <Radio.Group defaultValue={false} onChange={onChange}>
          <Radio value={1}>
            <p>{user.email}</p>
          </Radio>

          <Radio value={2}>
            <p>{user.cel}</p>
          </Radio>

          <Radio value={3}>
            <p>Não reconheço nenhum canal de contato</p>
          </Radio>
        </Radio.Group>
      </ContainerRadio>
      <div className="container-submit">
        <Button
          className="btn-send" 
          htmlType="submit"
          loading={loading}
          disabled={!type}
        >
          ENVIAR
        </Button>
      </div>
      </Form>
    </Container>
  )
}