import styled from 'styled-components';

export const ContainerFooter = styled.div`
  width: 100%;
  background-color: #000;

  .container-links{
    display: flex;
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;

    @media(max-width: 800px) {
      flex-direction: column;
    }
  }

h5{
    color: #f4f4f5cc;
    margin: 0;
    padding: 0;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 30px;
    letter-spacing: 1px;
    font-size: 13px;
      :hover{
        cursor: pointer;
        color: #DBC79A;
    }
  }

  .container-modal{
    width: 400px;
    height: 400px;
    background-color: red;
    z-index: 999;
  }

  .footer-lgpd {
    text-align: center;
    padding: 32px 16px 52px;
    @media (max-width:567px) {
      display: flex;
      flex-direction: column;
    }
    button {
      background: none;
      border: 0;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 5px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      font-size: 16px;
      color: #fff;
      @media (max-width:567px) {
        display: none;
      }
    }
  }
`
export const ContainerFinalFooter = styled.div`
  display: flex;
  justify-content: space-around;

  .logo-dexco{
    width: 150px;
    margin-bottom: 30px;
    @media(max-width: 800px) {
      margin-top: 20px;
    }
}
`
export const ContainerLogos = styled.div`
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  margin: 5px auto 5px;
  max-width: 800px;

  @media(max-width: 800px) {
    width: 100vw;
    display: grid;
    line-height: 50px;
    justify-items: center;
    margin: 0;
    justify-content: center;
  }

  .logo{
    height: 20px;
  }
`

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
`

export const ContentModal = styled.div`
  width: 65%;
  height: auto;
  background-color: white;
  margin: auto;
  transition: 1s;
  margin-top: 2%;
  border-radius: 5px;
  margin-bottom: 20px;

  @media(max-width: 800px) {
    width: 90%;
    font-size: 14px;
    padding: 10px;
  }

  .close{
    color: gray;
    float: right;
    margin: 10px 10px 0 0;
  }
`
export const ContainerTerm = styled.div`
  width: 670px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 20px;

  @media(max-width: 800px) {
    width: 100%;
  }

 b{
   font-weight: 800;
 }
  
  h2{
    font-size: 22px;
    font-weight: 800;
  }

  .href{
    text-decoration: none;
    color: #302728;
    :hover{
      text-decoration: underline;
    }
  }

  .href-email{
    color: #302728;
    font-weight: bold;
    text-decoration: none;
    :hover{
      text-decoration: underline;
    }
  }

  p{
    letter-spacing: 0.3px;
    line-height: 25px;

    @media(max-width: 800px) {
      line-height: 20px;
  }
  }
`