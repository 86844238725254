import React, { useEffect, useState } from "react";
import { 
  Divider, 
  Form, 
  Input,
  Button, 
  message 
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { 
  Authorizations,
  Container, 
  ContainerForm, 
  ContainerStep, 
  ModalTerm, 
  Terms, 
  Title 
} from "./style";
import profession from "../../constants/profession";
import states from "../../constants/state";
import apiCep from "../../services/apiCep";
import api from "../../services/api";
import { payloadRegisterAdapter, updateUserForm } from "../../adpater";
import { checkCPF, validateCel, validateEmail, validateName, validatePassword } from "../../utils/validation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function RegisterUser() {
  const key = 'updatable';
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();

  const [startDate, setStartDate] = useState();
  const [disableOtherOccupation, setDisableOtherOccupation] = useState(true);
  const [setOccupation] = useState();
  const [setOtherOccupation] = useState();
  const [authorizeFirstTerm, setAuthorizeFirstTerm] = useState();
  const [authorizeSecondTerm, setAuthorizeSecondTerm] = useState();
  const [messageErrorAuthorizeFirstTerm, setMessageErrorAuthorizeFirstTerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [userPast, setUserPast] = useState(false);

  moment.locale('pt-br');

  useEffect(()=> {
    const cpf = searchParams.get('cpf')
    if(cpf) {
      form.setFieldsValue({cpf})
      setAuthorizeFirstTerm(true)
    }

    const oldUser = JSON.parse(localStorage.getItem('oldUser'))
    if(oldUser) {
      const updateUser = updateUserForm(oldUser)
      form.setFieldsValue(updateUser)

      if (oldUser.past) {
        setUserPast(oldUser.past);
      }
    }
  },[form, searchParams])

  function handleOpenModal() {
    document.getElementById("link-modal").click();
  }

  function handleOtherOccupation(event){
    setOccupation(event);
    setDisableOtherOccupation(event.target.value !== 'Outros');
  }

  async function searchCep(event) {
    const value = event.target.value;

    const cep = value.replace(/\D/g, '');

    if (cep !== "") {
      const validateCep = /^[0-9]{8}$/;
      if(validateCep.test(cep)) {
        const response = await apiCep.searchCep(cep)
        form.setFieldsValue({
          city: response.data.localidade,
          address: response.data.logradouro,
          district: response.data.bairro,
          state: response.data.uf
        })
        if(response.data.erro === 'true') {
          message.error({ content: 'Cep inválido', key, duration: 2 });
        }
      }
    }
  }

  const cleanFields = () => {
    form.resetFields();
    setLoading(false);
  }

  async function onFinish(values) {
    const recaptcha = await executeRecaptcha();
    setLoading(true);
    localStorage.removeItem('oldUser')
    form
    .validateFields()
    .then(async (values) => {
      const payload = { ...values, recaptcha, register: authorizeFirstTerm, receiveCommunication: authorizeSecondTerm, past: userPast };
      message.loading({ content: 'Enviando...', key});
      if(payload && checkCPF(payload.cpf) && authorizeFirstTerm && searchCep){
        const request = payloadRegisterAdapter(payload);
        api.post('/users', request)
        .then(response => {
          message.success({ content: 'Seu cadastro foi concluído com sucesso!', key, duration: 2 });
          cleanFields();
          navigate('/success-register');
        }).catch(error => {
            setMessageErrorAuthorizeFirstTerm(true)
            message.error({ content: 'Erro ao concluir seu cadastro, tente novamente!', key, duration: 2 });
            setLoading(false);
          })
      } else {
        message.error({ content: 'Cpf inválido', key, duration: 2 });
      }
    })
  }

  function comparePassword() {
    const { password, confirmPassword } = form.getFieldsValue();

    if(confirmPassword === password) {
      return setPasswordValidate(true);
    }
    message.error({ content: 'As senhas não coincidem', key, duration: 3 });
  }

  return(
    <>
      <Container>
        <Title>
          Cadastro
        </Title>
        <Divider />
        <ContainerForm>
          <Form className="form" form={form} onFinish={onFinish} scrollToFirstError={true}>
            <div className="container-step">
              <ContainerStep>
                <div className="register-number-title">
                  <h3 className="register-number">1</h3>
                  <h3 className="register-title">DADOS PESSOAIS</h3>
                </div>
              </ContainerStep>
            </div>
            <Form.Item 
              name="firstName"
              className="label required"
              label="NOME"
              rules={[{ required: true, message: 'Insira seu nome' }]}
            >
              <Input name='name' onKeyUp={validateName} minLength={3} maxLength={50} className="input"/>
            </Form.Item>
            <Form.Item 
              name="lastName"
              className="label required"
              label="SOBRENOME"
              rules={[{ required: true, message: 'Insira seu sobrenome' }]}
            >
              <Input name='lastName' onKeyUp={validateName} minLength={3} maxLength={100} className="input"/>
            </Form.Item>
            <Form.Item 
              name="gender"
              className="label required"
              label="SEXO"
              rules={[{ required: true, message: 'Selecione um gênero' }]}
            >
              <select name="gender"  className="input-select">
                <option selected hidden>Selecione</option>
                <option value="female">Feminino</option>
                <option value="masculine">Masculino</option>
                <option value="other">Outros</option>
                <option value="uninformed">Prefiro não informar</option>
              </select>
            </Form.Item>
            <Form.Item 
              name="birthDate"
              className="label required"
              label="DATA DE NASCIMENTO"
              rules={[{ required: true, message: 'Selecione uma data' }]}
            >
              <DatePicker
                name="birthDate"
                className="input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                yearDropdownItemNumber={75}
                maxDate={(new Date()).setFullYear(new Date().getFullYear() - 18)}
                scrollableYearDropdown
              />
            </Form.Item>
            <div className="container-input">
              <Form.Item 
                name="cpf"
                className="label required"
                label="CPF"
                rules={[{ required: true, message: 'Insira seu cpf' }]}
              >
                <InputMask
                  className="input"
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                  disabled={searchParams.get('cpf')}
                />
              </Form.Item>
            </div>
            <Form.Item 
              name="occupation"
              className="label required"
              label="PROFISSÃO"
              rules={[{ required: true, message: 'Selecione uma profissão' }]}
            >
              <select 
                onChange={handleOtherOccupation}
                name="occupation" 
                placeholder="Selecione"
                className="input-select"
              >
                <option selected hidden>Selecione</option>
                {profession.map((item, index) => 
                  <option value={item.value} key={index}>
                    {item}
                  </option>
                )}
              </select>
            </Form.Item>
            {!disableOtherOccupation ? 
              <Form.Item 
                name="otherOccupation"
                className="label"
                label="OUTRA PROFISSÃO"
                onChange={(event) => setOtherOccupation(event.target.value)}
              >
                <Input name='otherOccupation' maxLength={50} minLength={5} className="input"/>
              </Form.Item>
            : null}
            <Form.Item 
              name="password"
              className="label required"
              label="SENHA"
              rules={[{ required: true, message: 'Insira uma senha' }]}
            >
              <Input.Password 
                name='password' 
                type="password"
                onBlur={validatePassword}
                className="input"
                maxLength={100}
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item 
              name="confirmPassword"
              className="label required"
              label="CONFIMAR SENHA"
              rules={[{ required: true, message: 'Confirme a senha' }]}
              >
              <Input.Password 
                name='confirmPassword' 
                type="password" 
                onBlur={comparePassword}
                className="input"
                maxLength={100}
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div className="container-rules">
              <b><p>Siga as regras para definir sua senha:</p></b>
              <p>Mínimo de 8 caracteres</p>
              <p>Ao menos uma letra maiúscula</p>
              <p>Ao menos uma letra minúscula</p>
              <p>Ao menos um caractere especial, dentre eles: _ ! @ # $ % & * ( ) { }</p>
              <p>Ao menos um número</p>
            </div>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">2</h3>
                  <h3 className="contact-title">CONTATOS</h3>
                </div>
              </ContainerStep>
            </div>
            <Form.Item 
              name="email"
              className="label required"
              label="E-MAil"
              rules={[{ required: true, validator: validateEmail }]}
            >
              <Input name='email' type="email" maxLength={100} className="input"/>
            </Form.Item>
            <Form.Item 
              name="cel"
              className="label required"
              label="CELULAR"
              rules={[{ required: true, validator: validateCel }]}
            >
              <InputMask
                className="input"
                mask="(99) 99999-9999"
                placeholder="() _____-____"
              />
            </Form.Item>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">3</h3>
                  <h3 className="contact-title">LOCALIZAÇÃO</h3>
                </div>
              </ContainerStep>
            </div>
            <Form.Item 
              name="cep"
              className="label required"
              label="CEP"
              rules={[{ required: true, message: 'Insira um cep' }]}
            >
              <InputMask 
                name='cep' 
                className="input" 
                onBlur={searchCep}
                mask="99999-999"
               />
            </Form.Item>
            <Form.Item 
              name="address"
              className="label required"
              label="Endereço"
              rules={[{ required: true, message: 'Insira um endereço' }]}
            >
              <Input name='address' maxLength={100} className="input-street"/>
            </Form.Item>
            <Form.Item 
              name="number"
              className="label required"
              label="Número"
              maxLength={50}
              rules={[{ required: true, message: 'Insira um número' }]}
            >
              <Input name='number' className="input-number"/>
            </Form.Item>
            <Form.Item 
              name="district"
              className="label required"
              label="BAIRRO"
              rules={[{ required: true, message: 'Insira um bairro' }]}
            >
              <Input name='district' className="input"/>
            </Form.Item>
            <Form.Item 
              name="complement"
              className="label"
              label="COMPLEMENTO"
            >
              <Input name='complement' maxLength={100} className="input"/>
            </Form.Item>
            <Form.Item 
              name="state"
              className="label required"
              label="ESTADO"
              rules={[{ required: true, message: 'Selecione um Estado' }]}
            >
              <select name="state" placeholder="Selecione" className="input-select">
              <option selected hidden>Selecione</option>
                {states.map((item, index) => 
                  <option value={item.value}  key={`state-${index}`}>
                    {item.state}
                  </option>
                )}
            </select>
            </Form.Item>
            <Form.Item 
              name="city"
              className="label required"
              label="CIDADE"
              rules={[{ required: true, message: 'Insira uma cidade' }]}
            >
              <Input name='city' className="input"/>
            </Form.Item>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">4</h3>
                  <h3 className="contact-title">TERMOS E CONSENTIMENTOS</h3>
                </div>
              </ContainerStep>
            </div>
            <Terms>
              <Authorizations>
                <label className="container-checkbox">
                  <input type="checkbox" checked={authorizeFirstTerm} onChange={(event) => setAuthorizeFirstTerm(event.target.checked)} name="register" className="checkbox-list-item" />
                  <span className="required-term" required>
                    Autorizo a coleta dos meus dados pessoais para cadastro no programa de relacionamento 
                    Clube Instaladores para fins de participação no programa; 
                  </span>                  
                </label>
                {messageErrorAuthorizeFirstTerm ? 
                  <p>Caso você não concorde com a coleta dos seus dados pessoais para as finalidades descritas, não poderemos prosseguir com seu cadastro.</p>
                : null }
                <label className="container-checkbox">
                  <input type="checkbox" onChange={(event) => setAuthorizeSecondTerm(event.target.checked)} name="receiveCommunication" className="checkbox-list-item" />
                  <span>
                    Aceito receber comunicações relacionadas ao Clube Instaladores (como novidades e informações 
                    sobre o programa, site, cadastro de notas fiscais, cursos, prêmios, benefícios e eventos) 
                    via E-mail, SMS ou WhatsApp; 
                  </span>
                </label>
              </Authorizations>
            </Terms>
            <ModalTerm>
              <p onClick={handleOpenModal}><b><u>Clique aqui</u></b> para acessar o nosso 
              <b> Aviso de Privacidade Clube Instaladores.</b></p>
            </ModalTerm>
            <div className="container-submit">
              <Button 
                className="btn-send" 
                htmlType="submit" 
                disabled={!passwordValidate}
                loading={loading}
              >
                SALVAR
              </Button>
            </div>
          </Form>
        </ContainerForm>
      </Container>
    </>
  )
}