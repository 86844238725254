import ImgHidraulicaBasica from '../assets/curso-hidraulica-basica.jpg';
import ImgTemporizados from '../assets/curso-temporizados.jpg';
import ImgBasicoPrediais from '../assets/curso-basico-prediais.jpg';
import ImgSistemaDescarga from '../assets/curso-sistema-descarga.jpg';
import ImgLoucas from '../assets/curso-loucas.jpg';
import ImgMetais from '../assets/curso-metais.jpg';
import ImgDuchasChuveiros from '../assets/curso-duchas-chuveiros.jpg';

const listCourses = {
  "course": [
    {
      "number": "1",
      "title": "HIDRÁULICA BÁSICA",
      "description": "Nesse curso são abordados os principais conceitos sobre hidráulica: dimensionamento de ramais e tubulações; medição de vazão, pressão alta e baixa; diferenciação entre pressão estática e dinâmica; conhecimento sobre as unidades de medida; tipos de água; e, orientações sobre perda de carga, golpe de aríete e manutenção e limpeza dos sistemas hidráulicos.",
      "link": "Teste",
      "image": ImgHidraulicaBasica
    },

    {
      "number": "2",
      "title": "BÁSICO PREDIAIS",
      "description": "Básicos prediais são os produtos responsáveis pelo sistema hidráulico básico como: registros de gaveta e pressão, esferas base para monocomandos e torneira boia. Abordamos a aplicabilidade dessas peças, sua compatibilidade com todos os tipos de aquecedores e acabamentos de metais, normas de pressão e suas temperaturas.",
      "link": "Teste",
      "image": ImgBasicoPrediais
    },

    {
      "number": "3",
      "title": "TEMPORIZADOS",
      "description": "O assunto tratado nesse curso são nossos produtos com sistema de fechamento automático, ou seja, interrompem o fluxo da água depois de um tempo determinado. Os conteúdos são: as características e diferenciais de cada tipo de produto, configurações e regulagens, economia de água, vazão de água, compatibilidade com reguladores de vazão, produtos com recurso antivandalismo; e, manutenção, limpeza e troca de reparos.",
      "link": "Teste",
      "image": ImgTemporizados
    },

    {
      "number": "4",
      "title": "METAIS",
      "description": "Nesse curso falamos sobre as características do material no processo de fabricação; diferenciação entre misturadores e monocomando; abordamos como realizar a substituição dos mecanismos de vedação e elemento filtrante; orientamos sobre instalação, manutenção e a regulagem de torneiras, misturadores e acabamentos de registro.",
      "link": "Teste",
      "image": ImgMetais
    },

    {
      "number": "5",
      "title": "LOUÇAS",
      "description": "Abordamos aqui a tipologia das cubas, bacias e bidês Deca; orientamos sobre a regulagem de bacias convencionais e com sistema de caixa acoplada; apresentamos as exigências da norma ABNT para produtos sanitários e realizamos recomendações sobre a instalação e manutenção desses produtos.",
      "link": "Teste",
      "image": ImgLoucas
    },

    {
      "number": "6",
      "title": "SISTEMA DE DESCARGA",
      "description": "Destacamos aqui nossos sistemas de descarga (caixa acoplada, caixa embutida e válvula Hydra), abordamos a intercambialidade de cada sistema, falamos sobre a vantagem e desvantagem de cada tipologia analisando o ambiente e todo sistema de tubulação hidráulico.",
      "link": "Teste",
      "image": ImgSistemaDescarga
    },


    {
      "number": "7",
      "title": "DUCHAS E CHUVEIROS",
      "description": "Abordamos aqui, as características dos chuveiros e duchas: como vazão, potência, volume de saída de água, orientamos sobre as especificações desses produtos levando em conta o espalhador, sua compatibilidade com desviadores, monocomandos e sistemas de aquecimentos.",
      "link": "Teste",
      "image": ImgDuchasChuveiros
    },
  ]
}

export default listCourses;