import { Button, Divider, Form, message } from 'antd';
import InputMask from 'react-input-mask';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authorizations, Container, ContainerRegister, ModalTerm, Terms, Title } from './style';
import { checkCPF } from "../../../utils/validation";
import api from '../../../services/api';

export function RegisterCpf({ handleForm, dataResponse }) {

  const [loading, setLoading] = useState(false);
  const [authorizeFirstTerm, setAuthorizeFirstTerm] = useState();
  const [setAuthorizeSecondTerm] = useState();
  const [messageErrorAuthorizeFirstTerm, setMessageErrorAuthorizeFirstTerm] = useState(false);
  const navigate = useNavigate();

  const key = 'updatable';

  const [form] = Form.useForm();
  function handleOpenModal() {
    document.getElementById("link-modal").click();
  }

  async function onFinish() {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        if(checkCPF(values.cpf) && authorizeFirstTerm){
          api.post('/users/cpf', {cpf: values.cpf.replace(/\.|-/gm,'')})
          .then((response) => {
            if(!response.data.email) {
              navigate(`/register-user?cpf=${values.cpf.replace(/\.|-/gm,'')}&authorize=true`);
            } else if(response.data.status === 'User already exists'){
              navigate('/login');
            } else {
              dataResponse(response.data);
              handleForm(values);
            }
            setLoading(false);
          })
        } else {
          setMessageErrorAuthorizeFirstTerm(true)
          message.error({ content: 'Error ao salvar cpf, tente novamente!', key, duration: 2 });
          setLoading(false);
        }
        if(!checkCPF(values.cpf)) return message.error({ content: 'Cpf inválido', key, duration: 2 });
      })
      .catch((error) => {
        message.error({ content: 'Error ao salvar cpf, tente novamente!', key, duration: 2 });
        setLoading(false);
      })
    }

  return(
    <Container>
      <Title>
        Cadastro
      </Title>
      <Divider />
      <div className="span-register">
        <span>Bem vindo ao <b>Clube Instaladores</b>, inicialmente informe seu CPF</span>
      </div>
      <ContainerRegister>
        <Form className="form" onFinish={onFinish} form={form}>
          <Form.Item 
            name="cpf"
            className="label"
            label="CPF"
            rules={[{ required: true, message: 'Insira seu cpf' }]}
          >
            <InputMask
              className="input"
              mask="999.999.999-99"
              placeholder="000.000.000-00"
            />
          </Form.Item>
          <Terms>
            <Authorizations>
              <label className="container-checkbox">
                <input type="checkbox" onChange={(event) => setAuthorizeFirstTerm(event.target.checked)} name="register" className="checkbox-list-item" />
                <span className="required-term" required>
                  Autorizo a coleta dos meus dados pessoais para cadastro no programa de relacionamento 
                  Clube Instaladores para fins de participação no programa; 
                </span>                  
              </label>
              {messageErrorAuthorizeFirstTerm ? 
                <p>Caso você não concorde com a coleta dos seus dados pessoais para as finalidades descritas, não poderemos prosseguir com seu cadastro.</p>
              : null }
              <label className="container-checkbox">
                <input type="checkbox" onChange={(event) => setAuthorizeSecondTerm(event.target.checked)} name="receiveCommunication" className="checkbox-list-item" />
                <span>
                  Aceito receber comunicações relacionadas ao Clube Instaladores (como novidades e informações 
                  sobre o programa, site, cadastro de notas fiscais, cursos, prêmios, benefícios e eventos) 
                  via E-mail, SMS ou WhatsApp; 
                </span>
              </label>
            </Authorizations>
          </Terms>
          <ModalTerm>
            <p onClick={handleOpenModal}><b><u>Clique aqui</u></b> para acessar o nosso 
            <b> Aviso de Privacidade Clube Instaladores.</b></p>
          </ModalTerm>
          <div className="container-submit">
            <Button 
              className="btn-send" 
              htmlType="submit" 
              disabled={!authorizeFirstTerm}
              loading={loading}
            >
              ENVIAR
            </Button>
          </div>
        </Form>
      </ContainerRegister>
    </Container>
  )
}