import { Divider } from "antd";
import { useEffect } from "react";
import { Container, Title } from "./style";

export function DeleteUser() {

	useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

	return (
		<>
			<Container>
				<Title>
					Seus dados foram excluídos com sucesso
				</Title>
				<Divider />
				<div className="span-delete">
					<span>
						Seus dados foram excluídos da nossa base de dados com sucesso. Caso mude de ideia, 
						realize o cadastro novamente e garanta todos os benefícios que o Clube Instaladores pode oferecer.
					</span>
				</div>
			</Container>
		</>
	)
}