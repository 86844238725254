import { CaretRightOutlined } from "@ant-design/icons";
import { Modal, Skeleton } from "antd";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useEffect } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import listExtraContent from "../../constants/extra-content";
import api from "../../services/api";
import { Container, ContainerVideos, Video } from "./style";

export function Videos() {
	const token = JSON.parse(localStorage.getItem('token'))
  const jwtToken = jwtDecode(token.access_token)
  const [userName, setUserName] = useState()
	const id = jwtToken.idExternal
  const cpf = localStorage.getItem('cpf')
	const [userLastName, setUserLastName] = useState()
  const [loading, setLoading] = useState(true)
  const [profession, setProfession] = useState()
	const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [modalTitle, setModalTitle] = useState()

	useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

	useEffect(() => {
    async function getUser() {
      const responseApi = await api.get(`/users?id=${id}&cpf=${cpf}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })
      const occupation = responseApi.data.occupation.occupation.occupation
      const otherOccupation = responseApi.data.occupation.occupation.otherOccupation
      setUserName(responseApi.data.firstName)
      setUserLastName(responseApi.data.lastName)
      setProfession(occupation ? occupation : otherOccupation)
      setLoading(false)
    }

    getUser()
  },[cpf, id, token?.access_token])

	const handleCancel = () => {
    setIsModalVisible(false);
    setModalContent('');
  };

	const videoIframe = (video) => {
    return (
      <iframe width="560" height="315"
        src={`https://www.youtube.com/embed/${video.videoId}?rel=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ width:'100%', height:'100%' }}>
      </iframe>
    )
  }

	const showModal = () => {
    setIsModalVisible(true);
  };

	const selectVideo = (event, video) => {
    event.preventDefault();
    setModalContent(videoIframe(video));
    setModalTitle(video.title);
    showModal();
  }

	return(
		<>
			<Container>
				<div className='page-header'>
          <Skeleton paragraph={{ rows: 1 }} loading={loading} active>
            <h2 className='user-name'>{userName}</h2>
            <h2 className='user-last-name'>{userLastName}</h2>
            <Link to='/edit-user'>
              <button className='btn-user-edit'>Editar dados</button>
            </Link>
            <h3 className='user-profession'>{profession}</h3>
          </Skeleton>
        </div>
        <h1 className='page-title'>Aproveite nossos Conteúdos Extras</h1>
				<Link to="/home">
					<h2 className='new-content'>
						<FiArrowLeftCircle size={17} className='icon-arrow'/>
						Voltar para tela inicial
					</h2>
				</Link>
				<ContainerVideos>
					{listExtraContent.extraContent.map((item, index) => 
						<Video key={`extra-content-item-${index}`}>
							<div className='extra-content-item'>
							{(item.videoExternalLink) ?
								<a href={item.videoExternalLink} target='_blank' rel='noopener noreferrer'
									style={{ backgroundImage: `url(${item.cover})` }} title={item.title}>
									<span className='icon'><CaretRightOutlined /></span>
								</a>
								:
								<a href='/#' onClick={(e) => selectVideo(e, item)}
									style={{ backgroundImage: `url(${item.cover})` }} title={item.title}>
									<span className='icon'><CaretRightOutlined /></span>
								</a>}
								<p className='title'>{item.title}</p>
							</div>
						</Video>
					)}
					</ContainerVideos>
					<Modal title={modalTitle} className='modal-extra-content' destroyOnClose={true}
          visible={isModalVisible} onCancel={handleCancel} footer={null} centered>
          {modalContent}
        </Modal>
			</Container>
		</>	
	)
}