import { Container, ContainerRegister, Title } from "./style";
import { Button, Divider, Form, Input, message } from "antd";
import { useState } from "react";
import { validatePasswordWithMessage } from "../../utils/validation";
import api from "../../services/api";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export function RegisterNewPassword() {
  const key = 'updatable';
  const [form] = Form.useForm();
  const [setLoading] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const cpf = searchParams.get('cpf');

  function onFinish(value) {
    const payload = { token, cpf, newPassword: value.newPassword };
    setLoading(true);
    form
    .validateFields()
    .then(async (values) => {
      message.loading({ content: 'Enviando...', key});
      if(payload){
        await api.post('/users/recreate-password', payload);
        setLoading(false);
        navigate('/success-new-password');
      }
    }).catch((error) => {
      message.error({ content: 'Erro ao redefinir senha, tente novamente!', key, duration: 2 });
      setLoading(false);
    })
  }

  function comparePassword() {
    const { password, newPassword } = form.getFieldsValue();  
    
    if(newPassword === password) {
      return setPasswordValidate(true);
    }
    message.error({ content: 'As senhas não coincidem', key, duration: 3 });
  }

  return(
    <>
      <Container>
        <Title>
          Cadastre sua nova senha
        </Title>
        <Divider />
        <ContainerRegister>
          <Form className="form" onFinish={onFinish} form={form}>
            <Form.Item 
              name="email"
              className="label"
              label="E-MAIL"
              rules={[
                {
                  required: true,
                  message: 'Insira seu e-mail',
                },
              ]}
            >
              <Input className="input" name="email" type="email" />
            </Form.Item>
            <Form.Item 
              name="password"
              className="label"
              label="SENHA"
              rules={[
                {
                  required: true,
                  message: 'Insira uma senha',
                },
              ]}
            >
              <Input.Password 
                name='password' 
                type="password" 
                onBlur={validatePasswordWithMessage}
                className="input"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item 
              name="newPassword"
              className="label"
              label="CONFIRMAR SENHA"
              rules={[
                {
                  required: true,
                  message: 'Confirme a senha',
                },
              ]}
            >
              <Input.Password 
                name='newPassword' 
                type="password"
                onBlur={comparePassword}
                className="input"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div className="container-submit">
              <Link to='/login'>
                <Button className="btn-cancel" >
                  CANCELAR
                </Button>
              </Link>
              <Button 
                className="btn-send" 
                htmlType="submit"
                disabled={!passwordValidate}
              >
                ATUALIZAR
              </Button>
            </div>
          </Form>
        </ContainerRegister>
      </Container>
    </>
  )
}