import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
  height: auto;
  margin-bottom: 100px;

  .span-delete{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    letter-spacing: 1px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }

  span {
    padding: 2.5px;

    @media(max-width: 800px) {
      padding: 7px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;