import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .span-register {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }

  .container-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
  }

  .btn-send {
    font-size: 2%;
    width: 124px;
    height: 35px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
      :hover{
        background-color: white;
        color: black;
        border: 1px solid black;
      }
      :disabled{
        background-color: #D6D6D6;
        color: black;
        cursor: not-allowed;
      }

      @media(max-width: 800px) {
        width: 90vw;
        margin: auto;
        height: 45px;
        margin-top: 70px;
      }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContainerRadio = styled.div`
  width: 550px;
  height: 110px;
  margin: auto;
  display: flex;
  justify-content: center;
  line-height: 30px;
  margin-top: 80px;
  margin-bottom: 40px;
  flex-direction: column;

  p {
    width: 550px;
    border-bottom: 1px solid black;

    @media(max-width: 800px) {
      width: 80vw;
    }
  }

  @media(max-width: 800px) {
    width: 80vw;
    margin: auto;
    height: 45px;
    margin-top: 70px;
    margin-left: 20px;
  }
`;