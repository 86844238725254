import { 
  ComponentHeader, 
  DecaArea,
  MenuAndLogin,
  ContainerMenu,
  Menu
} from "./style";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Instaladores from "../../assets/logo-instaladores.svg";
// import Dexperience from "../../assets/logo-dexperience.svg";
import { useState } from "react";
import { useEffect } from "react";

export function Header({ isUserLogged }) {

  const [isLogged, setIsLogged] = useState(isUserLogged);

  useEffect(() => {
    const isUserLogged = localStorage.getItem('token')
    setIsLogged(isUserLogged)
  }, [isUserLogged])

  const [menuVisible, setMenuVisible] = useState(false);
  
  const logout = () => {
    localStorage.removeItem('token')
    window.location = '/login'
  }

  return(
    <>
      <ComponentHeader>
        <div className="href">
          <a href="https://www.deca.com.br" rel="noreferrer noopener">
            <LeftOutlined style={{ fontSize: "17px", color: "#BFBFBF", marginRight: "5px" }} />
            Voltar para deca.com.br
          </a>
        </div>
        {/* <a href="https://www.dexperience.com.br" target="_blank" rel="noreferrer noopener">
          <img src={Dexperience} alt="Dexperience" className="deca-img" />
        </a> */}
      </ComponentHeader>
      <DecaArea>
        <Link to="/">
          <img src={Instaladores} alt="Clube Instaladores"/>
        </Link>
        <MenuAndLogin isOpen={menuVisible} onClick={() => setMenuVisible(!menuVisible)}>
          <ContainerMenu>
            <Menu isOpen={menuVisible}>
              <Link to={ !isLogged ? '/' : '/home' }>HOME</Link>
              { !isLogged ? <Link to="/register">CADASTRE-SE</Link> : null }
              <Link to={ isLogged ? '/courses-logged' : '/courses' }>CURSOS</Link>
              { isLogged ? <Link to="/videos-logged">CONTEÚDO</Link> : null }
              <Link to="/contact">CONTATO</Link>
            </Menu>
          </ContainerMenu>
          { isLogged ?
            <button onClick={logout} className="link-login">
              SAIR
            </button>
            :
            <Link to="/login" className="link-login">
              LOGIN
            </Link>
          }
        </MenuAndLogin>
      </DecaArea>
    </>
  )
}