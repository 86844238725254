import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .span-forgot-password {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 1px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }

  .span-send-email {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    letter-spacing: 1px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContainerEmail = styled.div`
  width: 568px;
  height: 103px;
  background-color: #F0F0F0;
  margin: auto;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: black;
    margin: 0;
    font-size: 22px;
    font-weight: bold;
  }

  @media(max-width: 800px) {
    width: 90vw;
    height: 80px;
  }
`;