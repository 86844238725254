import { useState } from 'react';
import { RegisterCpf } from '../../components/Register/RegisterCpf';
import { ChooseToken } from '../../components/ChooseToken';

export function Register() {
  const [payload, setPayload] = useState({ });
  const [user, setUser] = useState();

  const handlePayload = (values) => {
    setPayload({ ...payload, ...values });
  }

  return (
    <>
      { 
        !payload.cpf ? 
          <RegisterCpf dataResponse={setUser} handleForm={handlePayload}/> :
          <ChooseToken user={user} payload={payload} />
      }
    </>
  )
}