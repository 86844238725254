import styled from 'styled-components';

export const Container = styled.div`
  .ant-carousel {
    .slick-dots {
      li {
        width: auto;
        button {
          width: 12px;
          height: 12px;
          background-color: #C4C4C4;
          border-radius: 50%;
          border: 1px solid #000;
          opacity: 1;
        }
      }
      .slick-active button {
        background: #000;
      }
    }
  }
`
export const Banner = styled.div`
  .banner-item {
    display: flex;
    flex-wrap: wrap;
  }
  .bg {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width:767px) {
      width: 100%;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    &::after {
      content: '';
      display: block;
      /* background: linear-gradient(45deg, #eee9e8, #fdfaf9); */
      width: 100%;
      height: 30%;
      position: absolute;
      bottom: 0;
      z-index: -1;
      background: rgb(238,233,232);
      background: radial-gradient(circle,#c8bfbc 0%,rgba(253,250,249,1) 42%,rgba(255,255,255,1) 100%);
      /* background: linear-gradient(to right, white 0%, #c8bfbc 50%, white 100%); */
    }
  }
  .content {
    position: relative;
    width: 50%;
    background: #F4F4F5;
    display: flex;
    align-items: center;
    @media (max-width:767px) {
      width: 100%;
    }
    &>div {
      text-align: right;
      max-width: 600px;
      margin-left: -80px;
      @media (max-width:767px) {
        margin: 0 auto;
      }
    }
  }
  .description {
    padding: 24px;
    background: #E2E2E1;
    margin: 0;
    /* position: absolute; */
    width: 100%;
    /* max-width: 470px; */
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    i {
      font-size: 24px;
    }
    b {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .link {
    color: #fff;
    font-size: 20px;
    background-color: #000000;
    padding: 8px 16px;
    margin: 80px 24px 40px 0;
    display: inline-block;
    border: 1px solid #000000;
    transition: all 0.5s ease;
    &:hover {
      background-color: #fff;
      color: #000000;
    }
  }
  .follow {
    color: #000000;
    font-size: 24px;
    font-weight: 300;
    margin: 0 24px 24px 0;
    line-height: 1.2;
    b {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .banner-invert {
    .content {
      justify-content: flex-end;
      &>div {
        margin-right: -80px;
        text-align: left;
        @media (max-width:767px) {
          margin: 0 auto;
        }
      }
    }
    .link {
      margin: 80px 0 40px 24px;
    }
    .follow {
      margin: 0 0 24px 24px;
    }
  }
`
