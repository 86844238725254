import { Container, ContainerEmail, Title } from "./style";
import { Divider } from "antd";
import { useEffect, useState } from "react";
import api from "../../services/api";

export function NotificationSendEmailForgotPassword({ user }) {
  
  const [email, setEmail] = useState();

  useEffect(() => {
    getEmail();
  });

  async function getEmail() {
    const response = await api.post('/users/cpf', {cpf: user.cpf.replace(/\.|-/gm,'')});
    setEmail(response.data.email);
  }

  return(
    <>
      <Container>
        <Title>
          Esqueci minha senha
        </Title>
        <Divider />
        <div className="span-forgot-password">
          <span>Enviamos para o seu e-mail a solicitação de redefinição de senha para sua conta.</span>
        </div>
        <div className="span-send-email">
          <span>Solicitação enviada para o e-mail:</span>
        </div>
        <ContainerEmail>
          <h1>{email}</h1>
        </ContainerEmail>
      </Container>
    </>
  )
}