import styled from 'styled-components';

export const PrivacyPolicyWrap = styled.div`
  padding: 24px;
	h3 {
    font-size: 24px;
    font-weight: 800;
    margin-top: 30px;
  }
  h4 {
    font-size: 22px;
    font-weight: 800;
    margin-top: 20px;
  }
  .box {
    background: #E2E2E1;
    padding: 10px 20px;
    margin: 20px 0;
  }
  ul {
    font-size: 18px;
  }
  table {
    td, th {
      border: 1px solid #000;
      padding: 5px;
      text-align: center;
    }
  }
  .mt-0 {
    margin-top: 0;
  }
  .mt-3 {
    margin-top: 24px;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
`
