import React from "react";
import "antd/dist/antd.min.css";
import NavRoutes from "../src/routes/index";

function App() {
  return (
    <div className="App">
      <NavRoutes />
    </div>
  );
}

export default App;
