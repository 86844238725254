import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Skeleton } from "antd";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import InputMask from 'react-input-mask';
import { payloadRegisterAdapter, updateUserForm } from "../../adpater";
import profession from "../../constants/profession";
import states from "../../constants/state";
import api from "../../services/api";
import apiCep from "../../services/apiCep";
import { validateCel, validateEmail, validateName, validatePassword } from "../../utils/validation";
import { 
	Authorizations, 
	Container, 
	ContainerDeleteUser, 
	ContainerForm, 
	ContainerModal, 
	ContainerStep, 
	ContainerTerm, 
	ContentModal, 
	ModalTerm, 
	Terms, 
	Title 
} from "./style";

export function EditUser() {
  const key = 'updatable';
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState();
	const [setOccupation] = useState();
  const [disableOtherOccupation, setDisableOtherOccupation] = useState(true);
	const [setOtherOccupation] = useState();
  const [setPasswordValidate] = useState(false);
  const [messageErrorAuthorizeFirstTerm, setMessageErrorAuthorizeFirstTerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true)
  const [authorizeFirstTerm, setAuthorizeFirstTerm] = useState();
	const [authorizeSecondTerm, setAuthorizeSecondTerm] = useState();
  const [isModalVisible, setIsModalVisible] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

	const token = JSON.parse(localStorage.getItem('token'))
  const cpf = localStorage.getItem('cpf')
  const jwtToken = jwtDecode(token.access_token)
  const id = jwtToken.idExternal

  useEffect(() => {
    async function getUser() {
      const responseApi = await api.get(`/users?id=${id}&cpf=${cpf}`, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`
        }
      })
      setLoadingSkeleton(false)
      const updateUser = updateUserForm(responseApi.data)
      form.setFieldsValue(updateUser)
      setAuthorizeFirstTerm(responseApi.data.agreements.register) 
      setAuthorizeSecondTerm(responseApi.data.agreements.receiveCommunication)
      setDisableOtherOccupation(updateUser.occupation !== 'Outros');
    }
    getUser()
  },[cpf, form, id, token?.access_token])

	function openModal() {
    document.body.style.overflow='hidden'
    setIsModalVisible(true);
  }

  function closeModal() {
    document.body.style.overflowY='scroll'
    setIsModalVisible(false);
  }

	function handleOtherOccupation(event){
    setOccupation(event);
    setDisableOtherOccupation(event.target.value !== 'Outros');
  }

	function handleOpenModal() {
    document.getElementById("link-modal").click();
  }

  const cleanFields = () => {
    setLoading(false);
  }

  function deleteUser() {
		setLoading(true);
    message.loading({ content: 'Excluindo cadastro...', key});
      api.delete(`/users/${id}`, {headers: {Authorization: `Bearer ${token?.access_token}`}})
      .then(response => {
        window.location = '/success-delete-user'
        localStorage.clear()
      }).catch(error => {
        setMessageErrorAuthorizeFirstTerm(true)
        message.error({ content: 'Erro ao excluir cadastro, tente novamente!', key, duration: 2 });
        setLoading(false);
      })
  }

	async function onFinish(values) {
    const recaptcha = await executeRecaptcha();
		setLoading(true);
    form
    .validateFields()
    .then(async (values) => {
      const payload = { ...values, recaptcha, id, register: authorizeFirstTerm, receiveCommunication: authorizeSecondTerm };
      message.loading({ content: 'Enviando...', key});
      if(payload && authorizeFirstTerm && searchCep){
        const request = payloadRegisterAdapter(payload);
        api.put('/users', request, {headers: {Authorization: `Bearer ${token?.access_token}`}})
        .then(response => {
          message.success({ content: 'Seu cadastro foi editado com sucesso!', key, duration: 2 });
          cleanFields();
        }).catch(error => {
            setMessageErrorAuthorizeFirstTerm(true)
            message.error({ content: 'Erro ao editar seu cadastro, tente novamente!', key, duration: 2 });
            setLoading(false);
          })
      } else {
        message.error({ content: 'Cpf inválido', key, duration: 2 });
      }
    })
	}

	function comparePassword() {
    const { password, confirmPassword } = form.getFieldsValue();

    if(confirmPassword === password) {
      return setPasswordValidate(true);
    }
    message.error({ content: 'As senhas não coincidem', key, duration: 3 });
  }

	async function searchCep(event) {
    const value = event.target.value;

    const cep = value.replace(/\D/g, '');

    if (cep !== "") {
      const validateCep = /^[0-9]{8}$/;
      if(validateCep.test(cep)) {
        const response = await apiCep.searchCep(cep)
        form.setFieldsValue({
          city: response.data.localidade,
          address: response.data.logradouro,
          district: response.data.bairro,
          state: response.data.uf
        })
        if(response.data.erro === 'true') {
          message.error({ content: 'Cep inválido', key, duration: 2 });
        }
      }
    }
  }

	return(
		<>
      <Container>
        <Title>
          Meus dados
        </Title>
        <Divider />
        <ContainerForm>
          <Form className="form" form={form} onFinish={onFinish}>
            <div className="container-step">
              <ContainerStep>
                <div className="register-number-title">
                  <h3 className="register-number">1</h3>
                  <h3 className="register-title">DADOS PESSOAIS</h3>
                </div>
              </ContainerStep>
            </div>
            <Skeleton paragraph={{ rows: 6 }} loading={loadingSkeleton} active>
            <Form.Item 
              name="firstName"
              className="label required"
              label="NOME"
              rules={[{ required: true, message: 'Insira seu nome' }]}
            >
              <Input name='name' onKeyUp={validateName} minLength={3} maxLength={50} className="input"/>
            </Form.Item>
            <Form.Item 
              name="lastName"
              className="label required"
              label="SOBRENOME"
              rules={[{ required: true, message: 'Insira seu sobrenome' }]}
            >
              <Input name='lastName' onKeyUp={validateName} minLength={3} maxLength={100} className="input"/>
            </Form.Item>
            <Form.Item 
              name="gender"
              className="label required"
              label="SEXO"
              rules={[{ required: true, message: 'Selecione um gênero' }]}
            >
              <select name="gender" className="input-select">
                <option hidden>Selecione</option>
                <option value="female">Feminino</option>
                <option value="masculine">Masculino</option>
                <option value="other">Outros</option>
                <option value="uninformed">Prefiro não informar</option>
              </select>
            </Form.Item>
            <Form.Item 
              name="birthDate"
              className="label required"
              label="DATA DE NASCIMENTO"
              rules={[{ required: true, message: 'Selecione uma data' }]}
            >
              <DatePicker
                name="birthDate"
                className="input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                yearDropdownItemNumber={75}
                maxDate={(new Date()).setFullYear(new Date().getFullYear() - 18)}
                scrollableYearDropdown
              />
            </Form.Item>
            <div className="container-input">
              <Form.Item 
                name="cpf"
                className="label required"
                label="CPF"
              >
                <InputMask
                  className="input"
                  disabled
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"
                />
              </Form.Item>
            </div>
            <Form.Item 
              name="occupation"
              className="label required"
              label="PROFISSÃO"
              rules={[{ required: true, message: 'Selecione uma profissão' }]}
            >
              <select 
                onChange={handleOtherOccupation}
                name="occupation" 
                placeholder="Selecione"
                className="input-select"
              >
                <option hidden>Selecione</option>
                {profession.map((item, index) => 
                  <option value={item.value} key={index}>
                    {item}
                  </option>
                )}
              </select>
            </Form.Item>
            {!disableOtherOccupation ? 
              <Form.Item 
                name="otherOccupation"
                className="label"
                label="OUTRA PROFISSÃO"
                onChange={(event) => setOtherOccupation(event.target.value)}
              >
                <Input name='otherOccupation' disabled={disableOtherOccupation} maxLength={50} minLength={5} className="input"/>
              </Form.Item>
            : null }
            <Form.Item 
              name="password"
              className="label"
              label="SENHA"
            >
              <Input.Password 
                name='password' 
                type="password"
                onBlur={validatePassword}
                className="input"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                maxLength={100}
              />
            </Form.Item>
            <Form.Item 
              name="confirmPassword"
              className="label"
              label="CONFIMAR SENHA"
              >
              <Input.Password 
                name='confirmPassword' 
                type="password" 
                onBlur={comparePassword}
                className="input"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                maxLength={100}
              />
            </Form.Item>
            <div className="container-rules">
              <b><p>Siga as regras para definir sua senha:</p></b>
              <p>Mínimo de 8 caracteres</p>
              <p>Ao menos uma letra maiúscula</p>
              <p>Ao menos uma letra minúscula</p>
              <p>Ao menos um caractere especial, dentre eles: _ ! @ # $ % & * ( ) { }</p>
              <p>Ao menos um número</p>
            </div>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">2</h3>
                  <h3 className="contact-title">CONTATOS</h3>
                </div>
              </ContainerStep>
            </div>
            <Form.Item 
              name="email"
              className="label required"
              label="E-MAil"
              rules={[{ required: true, validator: validateEmail }]}
            >
              <Input name='email' maxLength={100} type="email" className="input"/>
            </Form.Item>
            <Form.Item 
              name="cel"
              className="label required"
              label="CELULAR"
              rules={[{ required: true, validator: validateCel }]}
            >
              <InputMask
                className="input"
                mask="(99) 99999-9999"
                placeholder="() _____-____"
              />
            </Form.Item>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">3</h3>
                  <h3 className="contact-title">LOCALIZAÇÃO</h3>
                </div>
              </ContainerStep>
            </div>
            <Form.Item 
              name="cep"
              className="label required"
              label="CEP"
              rules={[{ required: true, message: 'Insira um cep' }]}
            >
              <InputMask 
                name='cep' 
                className="input" 
                onBlur={searchCep}
                mask="99999-999"
               />
            </Form.Item>
            <Form.Item 
              name="address"
              className="label required"
              label="Endereço"
              rules={[{ required: true, message: 'Insira um endereço' }]}
            >
              <Input name='address' maxLength={100} className="input-street"/>
            </Form.Item>
            <Form.Item 
              name="number"
              className="label required"
              label="Número"
              rules={[{ required: true, message: 'Insira um número' }]}
            >
              <Input name='number' maxLength={50} className="input-number"/>
            </Form.Item>
            <Form.Item 
              name="district"
              className="label required"
              label="BAIRRO"
              rules={[{ required: true, message: 'Insira um bairro' }]}
            >
              <Input name='district' className="input"/>
            </Form.Item>
            <Form.Item 
              name="complement"
              className="label"
              label="COMPLEMENTO"
            >
              <Input name='complement' maxLength={100} className="input"/>
            </Form.Item>
            <Form.Item 
              name="state"
              className="label required"
              label="ESTADO"
              rules={[{ required: true, message: 'Selecione um Estado' }]}
            >
              <select name="state" placeholder="Selecione" className="input-select">
              <option hidden>Selecione</option>
                {states.map((item, index) => 
                  <option value={item.value}  key={`state-${index}`}>
                    {item.state}
                  </option>
                )}
            </select>
            </Form.Item>
            <Form.Item 
              name="city"
              className="label required"
              label="CIDADE"
              rules={[{ required: true, message: 'Insira uma cidade' }]}
            >
              <Input name='city' className="input"/>
            </Form.Item>
            <div className="container-step">
              <ContainerStep>
                <div className="contact-number-title">
                  <h3 className="contact-number">4</h3>
                  <h3 className="contact-title">TERMOS E CONSENTIMENTOS</h3>
                </div>
              </ContainerStep>
            </div>
            <Terms>
              <Authorizations>
                <label className="container-checkbox">
                  <input type="checkbox" checked={authorizeFirstTerm} onChange={(event) => setAuthorizeFirstTerm(event.target.checked)} name="register" className="checkbox-list-item" />
                  <span className="required-term" required>
                    Autorizo a coleta dos meus dados pessoais para cadastro no programa de relacionamento 
                    Clube Instaladores para fins de participação no programa; 
                  </span>                  
                </label>
                {messageErrorAuthorizeFirstTerm ? 
                  <p>Caso você não concorde com a coleta dos seus dados pessoais para as finalidades descritas, não poderemos prosseguir com seu cadastro.</p>
                : null }
                <label className="container-checkbox">
                  <input type="checkbox" checked={authorizeSecondTerm} onChange={(event) => setAuthorizeSecondTerm(event.target.checked)} name="receiveCommunication" className="checkbox-list-item" />
                  <span>
                    Aceito receber comunicações relacionadas ao Clube Instaladores (como novidades e informações 
                    sobre o programa, site, cadastro de notas fiscais, cursos, prêmios, benefícios e eventos) 
                    via E-mail, SMS ou WhatsApp; 
                  </span>
                </label>
              </Authorizations>
            </Terms>
            <ModalTerm>
              <p onClick={handleOpenModal}><b><u>Clique aqui</u></b> para acessar o nosso 
              <b> Aviso de Privacidade Clube Instaladores.</b></p>
            </ModalTerm>
            <div className="container-submit">
              <Button
                className="btn-send" 
                htmlType="submit" 
                disabled={!authorizeFirstTerm}
                loading={loading}
              >
                SALVAR
              </Button>
            </div>
          </Skeleton>
        </Form>
      </ContainerForm>
				<ContainerDeleteUser>
					<Button className="button-delete" onClick={openModal}>
						Não quero mais participar do Clube Instaladores
					</Button>
				</ContainerDeleteUser>
      </Container>
			<ContainerModal style={{display: isModalVisible ? 'block' : 'none' }}>
        <ContentModal>
          <ContainerTerm>
						<h2>Você realmente deseja excluir seus dados?</h2>
						<span>Ao realizar essa ação seu cadastro será excluído do <b>Clube Instaladores</b> e você
							perderá seu acesso aos conteúdos exclusivos.
						</span>
          </ContainerTerm>
					<div className="container-buttons">
						<Button className="btn-cancel" onClick={closeModal}>
							Cancelar
						</Button>
						<Button className="btn-delete" onClick={deleteUser}>
							Excluir Dados
						</Button>
					</div>
        </ContentModal>
      </ContainerModal>
		</>
	)
}