import { message } from "antd";

export function checkCPF( cpf ) {
  if (!cpf) return false
  cpf = cpf.replace('.', '').replace('.', '').replace('-', '')
  let Soma
  let Resto
  Soma = 0

  const validateCpf = cpf;
  
  const invalidsCpfs= [
    '00000000000', 
    '11111111111', 
    '22222222222', 
    '33333333333', 
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ]

  if(invalidsCpfs.includes(validateCpf)) return false;

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i), 10) * (11 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11))  Resto = 0
  if (Resto !==  parseInt(cpf.substring(9, 10), 10) ) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i), 10) * (12 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11))  Resto = 0
  if (Resto !==  parseInt(cpf.substring(10, 11), 10 ) ) return false
  return true
}

export function validatePassword(event) {
  const key = 'updatable';

  const value = event.target.value;
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!()_%])[0-9a-zA-Z$*&@#!()_%]{8,}$/;
  if(!regex.test(value)) {
    message.error({ content: 'Senha fora do padrão', key, duration: 3 });
  }
}

export function validateName(event) {
  const value = event.target.value;

  const regex = value.replace(/[0-9]/g, '')
  event.target.value = regex
}

export async function validateEmail(_, value) {
  if(!value) {
    return Promise.reject('Insira um e-mail')
  }
  const validate = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
  if(!validate) {
    return Promise.reject('E-mail inválido')
  }
}

export function validatePasswordWithMessage(event) {
  const key = 'updatable';

  const value = event.target.value;
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!()_%])[0-9a-zA-Z$*&@#!()_%]{8,}$/;
  if(!regex.test(value)) {
    message.error({ content: 'Senha fora do padrão', key, duration: 3 });
    message.info({ 
      content: 'A senha deve conter no mínimo 8 caracteres. Ao menos uma letra maiúscula. ' 
      + 'Ao menos uma letra minúscula, ao menos um número. '
      + ' Ao menos um caractere especial, dentre eles: _ ! @ # $ % & * ( ) { }', 
      key,
      style: {
        width: '70vw',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      duration: 7
  });
  }
}

export async function validateCel(_, value) {
  const key = 'updatable';
  
  const invalidCel= [
    '(00) 00000-0000', 
    '(11) 11111-1111', 
    '(22) 22222-2222', 
    '(33) 33333-3333', 
    '(44) 44444-4444',
    '(55) 55555-5555',
    '(66) 66666-6666',
    '(77) 77777-7777',
    '(88) 88888-8888',
    '(99) 99999-9999'
  ]

  const validate = invalidCel.includes(value)

  if(!value) {
    return Promise.reject('Insira um número de celular')
  }

  if(validate) {
    message.error({ content: 'Número de celular inválido', key, duration: 3 });
    return Promise.reject('Número de celular inválido')
  }
}