import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .span-confirmation {
    width: 550px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 40px;
    letter-spacing: 0.5px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }

    span {
      font-size: 18px;
    }
  }

  .container-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }

  .btn-send {
    font-size: 2%;
    width: 166px;
    height: 42px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
      :hover{
        background-color: white;
        color: black;
        border: 1px solid black;
      }
      :disabled{
        background-color: #D6D6D6;
        color: black;
        cursor: not-allowed;
      }

      @media(max-width: 800px) {
        width: 90vw;
        margin: auto;
        height: 50px;
        margin-top: 30px;
      }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 23px;
  margin-top: 20px;
  font-weight: bold;

  @media(max-width: 800px) {
    width: 95vw;
  }
`;