import { Container, Title } from "./style";
import { Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export function SuccessRegister() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
      <Container>
        <Title>
          Cadastro realizado com sucesso!
        </Title>
        <Divider />
        <div className="span-confirmation">
          <span>
            Seus dados foram recebidos e agora você faz parte do Clube Instaladores Deca e Hydra, 
            o programa onde você pode realizar cursos gratuitos e receber certificados.
          </span>
        </div>
        <div className="container-submit">
          <Link to="/login">
            <Button 
              className="btn-send" 
              htmlType="submit"
            >
              Acessar Login
            </Button>
          </Link>
        </div>
      </Container>
    </>    
  )
}