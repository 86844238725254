import { Container, ContainerNotFound } from "./style";

export function NotFound() {
  return(
		<Container>
			<ContainerNotFound>
				<h1>Ops...</h1>
				<p>
					Parece que a página que você tentou acessar não existe mais :(
					Para retornar ao início, clique no botão abaixo :)
				</p>
				<div className="link-home">
					<a href="/">
						IR PARA HOME
					</a>
				</div>
			</ContainerNotFound>
		</Container>
  )
}