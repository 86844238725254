import styled from 'styled-components';
import menu from '../../assets/menu-dropdown.png';

export const ComponentHeader = styled.div`
  height: 40px;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 820px) {
    width: 100vw;
    height: 50px;
  }

  .deca-img{
    width: 130px;
    margin-right: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 820px) {
      margin-right: 10px;
    }
  }

  .href{
    margin-left: 30px;

    @media(max-width: 820px) {
      margin-left: 0;
    }
  }

  a{
    color: #BFBFBF;
    text-decoration: none;
    font-size: 16px;
  }  
`
export const DecaArea = styled.div`
  height: 90px;
  width: 100%;
  background-color: #F4F4F5;
  display: flex;
  align-items: center;

  @media(max-width: 820px) {
    height: 70px;
  }

  @media(max-width: 820px) {
    img{
      height: 80px;
      margin-right: 40px;
    }
  }

  a{
    width: 150px;
    height: 90px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    justify-content: center;
  }
`
export const MenuAndLogin = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: end;

  @media(max-width: 820px) {
    width: ${(props) => props.isOpen ? '200px' : '30px'};
    background-position-x: ${(props) => props.isOpen ? '155px' : '0'};
    background-position-y: ${(props) => props.isOpen ? '10px' : '0'};
    background-image: url(${menu});
    background-repeat: no-repeat;
    height: ${(props) => props.isOpen ? '340px' : '50px'};
    background-size: ${(props) => props.isOpen ? '30px' : '40px'};
    justify-content: center;
    z-index: 99;
    position: absolute;
    top: 50px;
    right: 0;
    margin-top: ${(props) => props.isOpen ? '0' : '10px'};
    margin-right: ${(props) => props.isOpen ? '0' : '20px'};
    background-color: ${(props) => props.isOpen ? '#F4F4F5' : ''};
    flex-direction: column;
  }

  .link-login {
    background-color: black;
    width: 150px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
      :hover{
        text-decoration: underline;
      }

      @media(max-width: 820px) {
        width: 100%;
        height: ${(props) => props.isOpen ? '70px' : '0'};
        font-size: ${(props) => props.isOpen ? '14px' : '0'};
        background-color: black;
        color: white;
        margin: 0;
        margin-top: 30px;
      }  
  }

  svg {
    color: black;
}
`

export const ContainerMenu = styled.div`
  width: 700px;
  height: inherit;
  margin-left: auto;
  margin-right: auto;

  @media(max-width: 820px) {
    height: 250px;
    width: auto;
  }
`

export const Menu = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media(max-width: 820px) {
    display: ${(props) => props.isOpen ? 'inline' : 'none'};
    flex-wrap: wrap;
  }

  a{
    text-decoration: none;
    color: #292728;
    font-weight: bold;
    letter-spacing: 1px;
    :hover{
      text-decoration: underline;
    }
    @media(max-width: 820px) {
      width: auto;
      height: 20px;
      color: black; 
      margin: 0;
      margin-top: 45px;
    }
  }
`