import { Container, Title } from "./style";
import { Button, Divider } from "antd";

export function SuccessNewPassword() {

  return(
    <>
      <Container>
        <Title>
          Cadastre sua nova senha
        </Title>
        <Divider />
        <div className="span-forgot-password">
          <span>Sua senha foi redefinida, para acessar seu login acesse o botão abaixo.</span>
        </div>
        <div className="container-submit">
          <Button
            className="btn-send" 
            htmlType="submit"
            href="/login" 
          >
            ENTRAR
          </Button>
        </div>
      </Container>
    </>
  )
}