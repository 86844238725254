import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 530px;
  max-height: auto;


  @media(max-width: 800px) {
    width: 100vw;
  }

  .span-login {
    width: 500px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContainerForm = styled.div`
  width: 75%;
  min-height: 300px;
  max-height: auto;
  margin: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  .form {
    width: 500px;
    height: 200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 1px !important;
    outline: 0;
    border-color: none;
    -webkit-box-shadow: none;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within {
    border-right-width: 1px !important;
    outline: 0;
    border-color: none;
    -webkit-box-shadow: none;
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }

  .label {
    font-size: 14px;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media(max-width: 800px) {
      width: 90vw;
      flex-direction: row;
    }
  }

  .input:focus{
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input-password:hover {
    border-color: black;
  }

  .input {
    width: 500px;
    height: 25px;
    color: #808080;
    padding: 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;

    @media(max-width: 800px) {
      width: 90vw;
    }
  }

  .link-forgot-password {
    color: #AFAFAF;
    text-decoration: underline;
    width: 100%;
  }

  .recaptcha {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;

    @media(max-width: 800px) {
      margin-top: 20px;
    }
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  .button-register {
    width: 160px;
    height: 35px;
    border-width: 2px solid;
    border-radius: 0px;
    border-color: #3C3C3B;
    margin-left: 20px;
    color: black;
    :hover{
      box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 5px 0px;
    }
  }

  .button-login {
    width: 124px;
    height: 35px;
    border-width: 2px solid;
    border-radius: 0px;
    border-color: #3C3C3B;
    margin-left: 20px;
    background-color: black;
    color: white;
    :hover{
      background-color: white;
      color: black;
      border: 1px solid black;
    }
    :disabled{
      background-color: #D6D6D6;
      color: black;
      cursor: not-allowed;
      border: none;
    }
  }

  span {
    font-size: 14px;
  }
`;