import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Divider, Form, Input, message } from "antd";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Link } from "react-router-dom";
import api from "../../services/api";
import { checkCPF } from "../../utils/validation";
import { Container, ContainerButtons, ContainerForm, Title } from "./style";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export function Login({ setIsLogged }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const key = 'updatable';
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function onFinish(values) {
    const recaptcha = await executeRecaptcha();
    const payload = {...values, recaptcha, cpf: values.cpf.replace(/\.|-/gm,'')};
    setLoading(true);
    form
    .validateFields()
    .then(async(values) => {
      if(!checkCPF(values.cpf)) {
        setLoading(false);
        return message.error({ content: 'Cpf inválido', key, duration: 2 });
      }
      const result = await api.post('/login', payload);
      setLoading(false);
      let token = result.data.auth
      token.date = new Date()
      localStorage.setItem('token', JSON.stringify(token))
      localStorage.setItem('cpf', payload.cpf)
      setIsLogged(!!token)
      navigate('/home');
    })
    .catch((error) => {
      message.error({ content: 'Cpf ou senha inválido!', key, duration: 2 });
      setLoading(false);
    })
  }

  return (
    <>
      <Container>
        <Title>
          Login
        </Title>
        <Divider />
        <div className="span-login">
          <span>Faça o seu login ou <u>cadastre-se:</u></span>
        </div>
        <ContainerForm>
          <Form className="form" form={form} onFinish={onFinish}>
            <Form.Item 
              name="cpf"
              className="label"
              label="CPF"
              rules={[{ required: true, message: 'Insira seu cpf' }]}
            >
              <InputMask
                className="input"
                mask="999.999.999-99"
                placeholder="000.000.000-00"
              />
            </Form.Item>
            <Form.Item 
              name="password"
              className="label"
              label="SENHA"
              rules={[{ required: true, message: 'Insira sua senha' }]}
            >
              <Input.Password
                name='password'
                className="input"
                type="password"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Link to="/forgot-password" className="link-forgot-password">
              Esqueci a senha
            </Link>
            <ContainerButtons>
              <Link to='/register'>
                <Button className="button-register">
                  CADASTRE-SE
                </Button>
              </Link>
              <Button 
                className="button-login"
                htmlType="submit"
                loading={loading}
              >
                ENTRAR
              </Button>
            </ContainerButtons>
          </Form>
        </ContainerForm>
      </Container>
    </>
  )
}