import styled from 'styled-components';

export const Label = styled.label`
  color: #DBC79A;
  letter-spacing: 1px;
  font-size: 15px;
  margin-bottom: 20px;
`
export const Title = styled.input`
  display: none;
  @media(max-width: 800px) {
    margin-bottom: 20px;
    height: auto;
    font-size: 14px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;

  @media(max-width: 800px) {
    margin-left: 20px;
}
`
export const Link = styled.a`
  text-decoration: none;
  color: #fff;
  max-width: 240px;
  letter-spacing: 1px;
  font-size: 14px;
  margin-bottom: 10px;
  :hover{
    color: #fff;
    text-decoration: underline;
  }
`