import React from 'react'
import moment from 'moment'

function PrivateRoute({ children, ...rest }) {
  const token = JSON.parse(localStorage.getItem('token'))
  const validToken = token?moment(token.date).diff(new Date(),'minutes')>-28:false

  const logout = () => {
    localStorage.removeItem('token')
    window.location = '/login'
  }

  return (
    validToken? (
      React.cloneElement(children, {match:rest.computedMatch})
    ) : (
      <div style={{display:'none'}}>
        { logout() }
      </div>
    )
  )
}

export default PrivateRoute
