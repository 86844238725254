import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  max-height: auto;
  margin: 0 auto;
  max-width: 1200px;
  padding: 16px;

  .page-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 34px;
  }
  .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 200px);
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    color: #1D1D1D;
    @media (max-width:767px) {
      max-width: initial;
    }
  }
  .user-last-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 200px);
    margin: 0;
    font-size: 32px;
    margin-left: 10px;
    font-weight: 700;
    color: #1D1D1D;
    @media (max-width:767px) {
      max-width: initial;
    }
  }
  .btn-user-edit {
    width: 160px;
    height: 35px;
    background-color: #000000;
    border: 1px solid #000000;
    color: #fff;
    margin: 0 0 0 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
    }
    @media (max-width: 880px) {
      margin-right: calc(100% - 30px);
      margin: 0;
    }
  }
  .user-profession {
    width: 100%;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    color: #1D1D1D;
  }

  .page-title {
    margin: 40px 0 5px;
    font-size: 24px;
    font-weight: 700;
    color: #1D1D1D;
  }

  .icon-arrow {
    margin-left: 5px;
    height: 23px;
  }

  .new-content {
    font-weight: bold;
    height: 20px;
    text-decoration: underline;
    letter-spacing: 1px;
    margin-bottom: 50px;
    display: flex;
    align-items: end;
    margin-top: 15px;
  }

  .extra-content-item {
    width: 350px;
    height: auto;
    margin-bottom: 60px;
    a {
      display: flex;
      height: 200px;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #000;
      &:hover {
        .icon {
          transform: scale(1.1);
        }
      }
    }
    .icon {
      border-radius: 50%;
      border: 2px solid #000;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      padding-left: 5px;
      box-sizing: border-box;
      overflow: hidden;
      transition: transform 0.5s ease;
      svg {
        font-size: 40px;
        color: #000;
      }
    }
    .title {
      color: #1D1D1D;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.2;
      letter-spacing: 1px;
      margin: 16px 2px 8px;
    }
    .description {
      font-weight: 400;
      font-size: 12px;
      color: #1D1D1D;
      margin: 8px 0;
    }
  }
`

export const ContainerSlide = styled.div`
  .ant-carousel {
    .slick-dots {
      li {
        width: auto;
        button {
          width: 12px;
          height: 12px;
          background-color: #D9D9D9;
          border-radius: 50%;
          opacity: 1;
        }
      }
      .slick-active button {
        background: #707070;
      }
    }
  }

  .anticon svg {
    font-size: 65px;

    @media (max-width: 800px) {
      font-size: 40px;
      margin-left: 5px;
    }
  }
`
export const Video = styled.div`
  .banner-item {
    display: flex;
    flex-wrap: wrap;
  }
  .bg {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width:767px) {
      width: 100%;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    &::after {
      content: '';
      display: block;
      /* background: linear-gradient(45deg, #eee9e8, #fdfaf9); */
      width: 100%;
      height: 30%;
      position: absolute;
      bottom: 0;
      z-index: -1;
      background: rgb(238,233,232);
      background: radial-gradient(circle,#c8bfbc 0%,rgba(253,250,249,1) 42%,rgba(255,255,255,1) 100%);
      /* background: linear-gradient(to right, white 0%, #c8bfbc 50%, white 100%); */
    }
  }
  .content {
    position: relative;
    width: 50%;
    background: #F4F4F5;
    display: flex;
    align-items: center;
    @media (max-width:767px) {
      width: 100%;
    }
    &>div {
      text-align: right;
      max-width: 600px;
      margin-left: -80px;
      @media (max-width:767px) {
        margin: 0 auto;
      }
    }
  }
  .description {
    padding: 24px;
    background: #E2E2E1;
    margin: 0;
    /* position: absolute; */
    width: 100%;
    /* max-width: 470px; */
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    i {
      font-size: 24px;
    }
    b {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .link {
    color: #fff;
    font-size: 20px;
    background-color: #000000;
    padding: 8px 16px;
    margin: 80px 24px 40px 0;
    display: inline-block;
    border: 1px solid #000000;
    transition: all 0.5s ease;
    &:hover {
      background-color: #fff;
      color: #000000;
    }
  }
  .follow {
    color: #000000;
    font-size: 24px;
    font-weight: 300;
    margin: 0 24px 24px 0;
    line-height: 1.2;
    b {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .banner-invert {
    .content {
      justify-content: flex-end;
      &>div {
        margin-right: -80px;
        text-align: left;
        @media (max-width:767px) {
          margin: 0 auto;
        }
      }
    }
    .link {
      margin: 80px 0 40px 24px;
    }
    .follow {
      margin: 0 0 24px 24px;
    }
  }
`

export const Course = styled.div`
  .percentage-progress {
    position: absolute;
    z-index: 99;
    margin-left: 10px;
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin: auto;
    margin-top: 2px;
    margin-left: 10px;
  }

  .container-progress {
    display: flex;
    align-items: center;
  }

  .course-item {
    width: 360px;
    height: 510px;
    background-color: #F4F4F6;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 60px;

    u {
      cursor: pointer;
    }

    @media (max-width: 880px) {
      max-width: 82vw;
      height: 535px;
      margin-left: 5px;
    }
  }

  .course-image {
    span {
      width: 110px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      margin-top: 10px;
      margin-left: 15rem;
      float: left;
      background-color: black;
      color: white;
      padding-top: 2px;
      z-index: 999;
      position: fixed;
      font-size: 14px;
      
      @media (max-width: 880px) {
        margin-left: calc(100% - 120px);
        position: relative;
      }
    }

    .certificate {
        background-color: #00A860;
        width: 160px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        margin-top: 10px;
        margin-left: 11.7rem;
        float: left;
        color: white;
        padding-top: 2px;
        z-index: 999;
        position: fixed;
        font-size: 14px;
        @media (max-width: 880px) {
          position: relative;
          margin-left: calc(100% - 170px);
        }
      }

    img {
      width: 358px;
      height: 190px;
      border-top-left-radius: 19px;
      border-top-right-radius: 20px;

      @media (max-width: 880px) {
        width: 100%;
      }
    }

    .default-image {
      width: 358px;
      height: 190px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: #1C1C1C;

      @media (max-width: 880px) {
        width: inherit;
      }

      .image-course {
        @media (max-width: 880px) {
          position: relative;
          top: -35px;
        }
      }
      
      h1 {
        color: white;
        font-size: 23px;
        padding-top: 40px;
        margin-left: 15px;
        font-weight: bold;
        height: 100px;
      }

      .logo {
        width: 150px;
        height: 90px;
        margin-left: 200px;
        margin-top: -5px;

        @media (max-width: 880px) {
          height: 70px;
          margin-left: 40vw;
        }

        @media (max-width: 768px) {
          margin-left: 27vw;
        }

        @media (max-width: 820px) {
          margin-left: 25vw;
        }
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
    color: black;
    margin-left: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    
    @media (max-width: 880px) {
      font-size: 17px;
      margin-left: 10px;
      margin-top: 10px;
      width: 290px;
      height: 30px;
    }
  }

  .information {
    color: black;
    margin-left: 15px;
    height: 240px;

    p {
      margin-bottom: 10px;
      line-height: 21px;
    }

    .test {
      margin-bottom: 30px;
      height: 40px;

      @media (max-width: 880px) {
        height: 60px;
        margin-bottom: 20px;
      }
    }

    .container-desc {
      height: 90px;

      @media (max-width: 880px) {
        height: 100px;
      }
    }

    @media (max-width: 800px) {
      margin-bottom: 8px;
      margin-left: 10px;
      height: 196px;
    }
  }

  .ant-progress-bg {
    height: 24px !important;
  }

  .ant-progress-inner {
    background-color: #D9D9D9;
  }

  .progress {
    width: 330px;

    @media (max-width: 880px) {
      max-width: 75vw;
    }

    b {
      letter-spacing: 1px;
    }
  }
`
export const ContainerSpin = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 80px;
    color: white;
  }
`
export const ContainerButtons = styled.div`
  width: 340px;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 800px) {
    width: 77vw;
  }

  @media (max-width: 540px) {
    width: 63vw;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .ant-btn>span {
    font-size: 14px;
    margin: auto;
  }

  .access-test {
    width: 130px;
    margin-right: 10px;
    border-radius: 0;
    font-weight: bold;
    border: 1px solid black;
    color: black;
    &:hover{
      color: black;
      box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 5px 0px;
    }

    @media (max-width: 880px) {
      padding: 0;
      margin: 0;
    }
  }

  .access-course {
    width: 130px;
    margin-right: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 0;
    &:hover{
      background-color: white;
      color: black;
      font-weight: bold;
      border: 1px solid black;
    }

    @media (max-width: 880px) {
      margin-left: 10px;
    }
  }
`