import styled from 'styled-components';

export const Container = styled.div`
	max-width: 100%;
	height: 300px;
`
export const ContainerNotFound = styled.div`
	width: inherit;
	height: 300px;
	margin-top: 100px;

	@media(max-width: 800px) {
		margin: 0;
		width: 100vw;
	}

	h1{
		text-align: center;
		color: #000;
		font-weight: 100;
		font-size: 20px;
	}

	p{
		width: 520px;
		height: 100px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		letter-spacing: 1px;
		line-height: 25px;
		color: #000;
		font-weight: 100;

		@media(max-width: 800px) {
			margin-top: 20px;
			line-height: 30px;
			width: 350px;
		}
	}

	.link-home{
		width: 200px;
		text-align: center;
		letter-spacing: 1px;
		margin-left: auto;
		margin-right: auto;

		@media(max-width: 800px) {
			margin-top: 60px;
		}
	}

	a{
		color: #000;
		font-weight: 600;
		&:hover {
			text-decoration: underline;
		}
	}

	@media(max-width: 800px) {
		width: 100vw;
	}
`