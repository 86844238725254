import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .span-register {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }

  .type-code {
    width: 570px;
    height: 30px;
    margin: auto;

    @media(max-width: 800px) {
      width: 90vw;
      margin-bottom: 30px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContainerEmail = styled.div`
  width: 568px;
  height: 103px;
  background-color: black;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: white;
    margin: 0;
  }

  @media(max-width: 800px) {
    width: 90vw;
    height: 80px;
  }
`;

export const ContainerCode = styled.div`
  width: 600px;
  height: 200px;
  display: flex;
  justify-content: center;
  margin: auto;

  .required {
      label::after {
      content: "*";
      color: red;
    }
  }

  @media(max-width: 800px) {
    width: 100vw;
    height: 250px;
  }

  .label {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: bold;

    @media(max-width: 800px) {
      width: 90vw;
    }
  }

  .input {
    width: 580px;
    height: 25px;
    color: #808080;
    padding: 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;

    @media(max-width: 800px) {
      width: 90vw;
    }
  }

  .input:focus {
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .container-submit {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(max-width: 800px) {
      height: 130px;
    }
  }

  .btn-send {
    font-size: 2%;
    width: 124px;
    height: 35px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    letter-spacing: 1px;
    margin-top: 10px;
      :hover{
        background-color: white;
        color: black;
        border: 1px solid black;
      }
      :disabled{
        background-color: #D6D6D6;
        color: black;
        cursor: not-allowed;
      }

      @media(max-width: 800px) {
        width: 90vw;
        margin: auto;
        height: 45px;
      }
  }
`;