import { Container, ContainerStart, Text, Image, Title, ContainerCourse, Card } from './style';
import ImageCourse from '../../assets/bg-curso.jpg';
import listCourses from '../../constants/courses';
import { Button, Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';

export function Courses() {

  return(
    <>
      <Container>
        <Title>
          Cursos
        </Title>
        <Divider />
        <ContainerStart>
          <Text>
            Cursos para você se qualificar e se tornar um instalador certificado pela Deca e Hydra.
          </Text>
          <Image>
            <img src={ImageCourse} alt="Instaladores" />
          </Image>
        </ContainerStart>
        <ContainerCourse>
          <Row align="middle">
          {listCourses.course.map ((item, index) => 
            <Col key={index} xs={16} xl={8}>
              <Card>
                <img src={item.image} alt="Curso" />
                <div className="course-number-title">
                  <h3 className="course-number">{item.number}</h3>
                  <h3 className="course-title">{item.title}</h3>
                </div>
                <div className="description">
                  <p>{item.description}</p>
                </div>
                <Link to="/login" className="link">
                  <Button className="btn-know-more">
                    Saiba mais
                  </Button>
                </Link>
              </Card>
            </Col>
          )}
          </Row>
        </ContainerCourse>
      </Container>
    </>
  )
}