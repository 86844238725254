import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;

  .container-step {
    width: 96%;
    height: 50px;
    margin-top: 30px;
  }

  .margin {
    margin: auto;
  }

  @media(min-width: 1920px) {
    width: 70%;
    margin: auto;
  }

  .span-register {
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;

    @media(max-width: 800px) {
      font-size: 16px;
      text-align: center;
      width: 350px;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 20px;
  font-weight: bold;
`;

export const ContainerStep = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  margin: auto;
  align-items: center;
  text-align: left;
  justify-content: start;

  .register-number-title {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .register-number {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
  }

  .register-title {
    margin-left: 15px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .contact-number-title {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .contact-number {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
  }

  .contact-title {
    margin-left: 15px;
    letter-spacing: 1px;
    font-weight: bold;
  }

  @media(max-width: 800px) {
    width: 90vw;
  }

  @media(min-width: 1920px) {
    width: 870px;
  }
`;

export const ContainerForm = styled.div`
  width: 54rem;
  min-height: 300px;
  max-height: auto;
  margin: auto;
  margin-bottom: 30px;

  select { 
    background-color: white;
  }

  .input:focus{
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 1px !important;
    outline: 0;
    border-color: none;
    -webkit-box-shadow: none;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus-within {
    border-right-width: 1px !important;
    outline: 0;
    border-color: none;
    -webkit-box-shadow: none;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }

  @media(max-width: 800px) {
    width: 90vw;
  }

  .react-datepicker__year-read-view--down-arrow {
    top: 5px;
  }

  .label {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: bold;

    @media(max-width: 800px) {
      width: 90vw;
      flex-direction: row;
    }
  }

    .required {
      label::after {
      content: "*";
      color: red;
    }
  }

  .form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 0 30px;
    flex-wrap: wrap;
    flex-direction: row;

    @media(min-width: 1920px) {
      width: auto;
      justify-content: flex-start;
    }
  }

  .input {
    width: 400px;
    height: 25px;
    color: #808080;
    padding: 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;

    @media(max-width: 800px) {
      width: 90vw;
    }
  }

  .input-select {
    width: 400px;
    height: 28px;
    color: #808080;
    padding: 3px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;
    @media(max-width: 800px) {
      width: 90vw;
    }
  }

  .input-street {
    width: 290px;
    height: 30px;
    color: #808080;
    padding: 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;

    @media(max-width: 800px) {
      width: 90vw;
      height: 40px;
    }
  }

  .input-number {
    width: 80px;
    height: 30px;
    color: #808080;
    padding: 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: #3C3C3B;

    @media(max-width: 800px) {
      width: 90vw;
      height: 40px;
    }
  }

  .container-input {
    width: 96%;
  }

  .ant-input:focus {
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-input-password:hover {
    border-color: black;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: none;
  }

  .container-rules {
    width: 97%;
    height: auto;
  }

  p {
    text-align: left;
    margin: 0;
    font-size: 15px;
    letter-spacing: 0.5px;
  }

  .container-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
  }

  .btn-send {
    font-size: 2%;
    width: 120px;
    height: 42px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    margin-top: 30px;
    letter-spacing: 1px;
      :hover{
        background-color: white;
        color: black;
        border: 1px solid black;
      }
      :disabled{
        background-color: #D6D6D6;
        color: black;
        cursor: not-allowed;
      }

      @media(max-width: 800px) {
        width: 90vw;
        margin: auto;
        margin-top: 30px;
      }
  }

  .recaptcha {
    width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media(max-width: 800px) {
      margin: auto;
    }
  }
`;

export const Terms = styled.div`
  width: 100%;
`

export const Authorizations = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  @media(min-width: 1920px) {
    margin: auto;
  }

  input:focus, input:focus-visible {
    border: 0;
    outline: none;
  }

  input[type='checkbox']::before {
    content: ' ';
    width: 25px;
    height: 25px;
    position: absolute;
    background-color: #ECECEC;
    border-radius: 30px;
    border: 2px solid black;
    margin: -6px 0 0 -5px;
  }
input[type='checkbox']:checked::after {
  content: '';
  border-color: black;
  border-style: solid;
  border-width: 11px 11px 11px 11px;
  position: absolute;
  margin-left: -3px;
  margin-top: -4px;
  border-radius: 50%;
  display: inline-block;
}

  span {
    margin-left: 25px;
    width: 100%;
  }

  .required-term::after{
    content: "*";
    color: red;
  }

  .container-checkbox {
    display: flex;
    margin-top: 20px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    font-size: 15px;
    align-items: center;
  }

  p {
    color: red;
    font-size: 15px;
  }
`

export const ModalTerm = styled.div`
  width: 95%;
  height: 40px;
  margin-top: 20px;
  cursor: pointer;

  @media(max-width: 800px) {
    height: 80px;
    margin: auto;
    margin-top: 20px;
  }
`

export const ContainerDeleteUser = styled.div`
  width: 380px;
  height: 100px;
  margin: auto;

  .button-delete {
    color: #888888;
    height: 35px;

    :hover{
      box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 5px 0px;
      border-color: rgba(0, 0, 0, 0.45);
      }
  }
`

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
`

export const ContentModal = styled.div`
  width: 55%;
  height: 350px;
  background-color: white;
  margin: auto;
  margin-top: 8%;
  transition: 1s;
  display: flex;
  flex-wrap: wrap;

  @media(min-width: 1920px) {
    width: 38vw;
    flex-direction: row;
  }

  @media(max-width: 800px) {
    width: 90%;
    font-size: 14px;
    padding: 10px;
    height: 400px;
  }

  .container-buttons {
    width: 160px;
    height: 100px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-top: -30px;
    flex-direction: column;

    @media(max-width: 800px) {
      margin-top: 90px;
    }
  }

  .btn-cancel {
    background-color: black;
    color: white;
    height: 42px;
    :hover{
      background-color: white;
      color: black;
      border-color: black;
    }
  }

  .btn-delete {
    height: 42px;
    border: 1px solid black;
    :hover{
        box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 5px 0px;
        color: black;
      }
  }

  span {
    font-size: 16px;
  }
`

export const ContainerTerm = styled.div`
  width: 100%;
  height: 100px;
  margin: auto;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media(min-width: 1920px) {
    width: 30vw;
    flex-direction: row;
  }

  @media(max-width: 800px) {
    width: 100%;
    margin-top: 30px;
  }
  
  h2{
    font-size: 22px;
    font-weight: 800;

    @media(max-width: 800px) {
      margin-left: 20px;
    }
  }

  span {
    width: 570px;

    @media(max-width: 800px) {
      margin-left: 20px;
    }
  }
`